<template>
    <div>
        <v-sheet class="px-6 pt-6">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between">
                    <v-avatar size="60" class="mr-6">
                        <img
                            class=""
                            src="/img/database.c82dbe43.svg"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1" style="flex: 8">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-0">
                                <h5 class="mr-2 mb-0">
                                    {{ createMode ? 'New Campaign' : campaignid }}                                   
                                </h5>                                
                                <p class="text--disabled mb-0">
                                    <v-chip      
                                        v-if="status==='draft'"                                  
                                        class="mr-4 rounded-pill"   
                                        color="blue"                                          
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc}}
                                    </v-chip>
                                    <v-chip     
                                        v-if="statusCalc==='running' || statusCalc==='queued'"                                                                     
                                        class="mr-4 rounded-pill"    
                                        color="primary"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc}}
                                    </v-chip>
                                    <v-chip             
                                        v-if="statusCalc==='completed'"                                                                                                
                                        class="mr-4 rounded-pill"    
                                        color="success"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc}}
                                    </v-chip>
                                    <v-chip             
                                        v-if="statusCalc==='stopped'"                                                                                                
                                        class="mr-4 rounded-pill"    
                                        color="orange"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc}}
                                    </v-chip>
                                    <v-chip      
                                        v-if="status==='archived'"                                  
                                        class="mr-4 rounded-pill"                                            
                                        label
                                        small                                        
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc}}
                                    </v-chip>
                                    <span v-if="id">No: {{ id }}</span>
                                </p>
                            </div>
                            <!-- <div>
                                
                            </div> -->
                        </div>
                        <div class="d-flex mb-0">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">{{ getSelectLabel(brandOptions, brand) }}</span
                                >
                            </p>
                        </div>
                        <!-- <p class="text--disabled">
                            {{ brand_description }}
                        </p> -->
                    </div>
                    <div class="flex-1 text-right" style="flex: 1">
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="save">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>                        
                    </div>
                    <div v-if="!createMode" class="flex-1 text-right" style="flex: 1">                        
                        <template>
                        <div class="text-center">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="ma-2" 
                                    dark 
                                    :loading="loading"                                     
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                        <!-- <v-icon class="pr-2">mdi-content-save</v-icon> -->
                                        Status
                                    </v-btn>
                                    <!-- <v-btn
                                    color="primary"
                                    dark                                
                                    >
                                    Dropdown
                                    </v-btn> -->
                                </template>
                                <v-list>
                                    <v-list-item @click="releaseCampaign">
                                        <v-list-item-title >
                                            Publish
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="completeCampaign">
                                        <v-list-item-title >
                                            Complete
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="archiveCampaign">
                                        <v-list-item-title >
                                            Archive
                                        </v-list-item-title>
                                    </v-list-item>                                    
                                </v-list>
                            </v-menu>
                        </div>
                        </template>
                    </div>
                </div>


                <base-card class="h-full">
                    <v-card-text>
                        <v-card>
                            <v-tabs vertical v-model="selectedTab" @change="tabChanged">
                                <v-tab @click="tabClicked_BasicInfo">
                                    <!-- <v-icon left>
                                        mdi-account
                                    </v-icon> -->
                                    Basic Info
                                </v-tab>
                                <v-tab @click="tabClicked_Content">
                                    <!-- <v-icon left>
                                        mdi-lock
                                    </v-icon> -->
                                    Content
                                </v-tab>
                                <v-tab @click="tabClicked_Delivery">
                                    <!-- <v-icon left>
                                        mdi-access-point
                                    </v-icon> -->
                                    Delivery
                                </v-tab>
                                <v-tab @click="tabClicked_Budget">
                                    <!-- <v-icon left>
                                        mdi-access-point
                                    </v-icon> -->
                                    Budget
                                </v-tab>
                                <v-tab @click="tabClicked_Summary">
                                    <!-- <v-icon left>
                                        mdi-access-point
                                    </v-icon> -->
                                    Summary
                                </v-tab>

                                <v-tab-item transition="false">
                                    <v-form v-model="basicInfoFormValid" ref="basicInfoForm">
                                        <v-card flat>
                                            <v-card-text>
                                                <p>
                                                    Configure the basic info for this Campaign
                                                </p>
                                                <v-row>
                                                    <v-col cols="12" md="9">
                                                        <v-row>
                                                            <v-col cols="12" md="9">
                                                                <v-text-field
                                                                    v-model="campaignid"
                                                                    :rules="requiredRules"
                                                                    :validate-on-blur="false" 
                                                                    label="Campaign ID"
                                                                />
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-select                                                                     
                                                                    v-model="brand"
                                                                    :items="brandOptions"                 
                                                                    label="Brand"                                             
                                                                    :rules="requiredRules"
                                                                    :validate-on-blur="false" 
                                                                    :clearable="true"                      
                                                                />                                                            
                                                            </v-col>                                        
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-textarea
                                                                    outlined                                                            
                                                                    label="Description"
                                                                    v-model="description"                                                                                                                                    
                                                                />
                                                            </v-col>                                                        
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="6">
                                                                <v-select                                                                     
                                                                    v-model="priority"
                                                                    :items="priorityOptions"                 
                                                                    label="Priority"                                             
                                                                    :rules="requiredRules"
                                                                    :validate-on-blur="true"
                                                                    :clearable="true"       
                                                                    :messages="['(higher number = higher priority)']"               
                                                                />
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="3">
                                                                <DatepickerPopup 
                                                                    v-model="startdate"
                                                                    label="Start Date"
                                                                    :rules="requiredRules"
                                                                ></DatepickerPopup>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <TimepickerPopup 
                                                                    v-model="starttime"
                                                                    label="Start Time"
                                                                    :rules="requiredRules"
                                                                ></TimepickerPopup>                                                                
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <DatepickerPopup 
                                                                    v-model="enddate"
                                                                    label="End Date"
                                                                    :rules="[...requiredRules, v=> endDateLaterThanStart(v, endtime, startdate, starttime)]"
                                                                ></DatepickerPopup>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <TimepickerPopup 
                                                                    v-model="endtime"
                                                                    label="End Time"
                                                                    :rules="[...requiredRules, v=> endTimeLaterThanStart(v, enddate, startdate, starttime)]"
                                                                ></TimepickerPopup>                                                                
                                                            </v-col>                                                            
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-card>
                                                                    <!-- <v-card-title>Checkboxes - Array</v-card-title> -->                                                                
                                                                    <v-card-text>
                                                                        <p>Delivery Networks that will be targeted.</p>                                                                        
                                                                        <v-checkbox v-for="networkOption in networkOptions" :key="networkOption.value"
                                                                            class="mt-0 mb-0 pt-0 pb-0"                                                                                                                      
                                                                            v-model="targetnetworks"                                                                        
                                                                            :label="networkOption.text"
                                                                            :value="networkOption.value"
                                                                            :rules="arrayRequiredRules"
                                                                        />
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>                                                        
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-form>                                    
                                </v-tab-item>
                                <v-tab-item transition="false" :eager="true">
                                    <v-card flat>
                                        <v-card-text>
                                            <p>
                                                Here you can add the assets that are going to be displayed as ads for this Campaign
                                            </p>                                               
                                            <v-form v-model="assetCreateFormValid" ref="assetCreateForm">
                                                <div v-for="(asset, index) in assets" :key="asset.id" >
                                                    <div v-if="!asset.editMode"
                                                        class="eg-todo-item d-flex justify-space-between flex-wrap align-center white pa-4 rounded"
                                                    >
                                                        <div class="d-flex align-center">                                                        
                                                            <h6
                                                                class="ma-0"                                                            
                                                            >
                                                                #{{index+1}}
                                                            </h6>
                                                            <img v-if="getThumbnailType(asset) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                            <img v-if="getThumbnailType(asset) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                            <img v-if="getThumbnailType(asset) === 'normal'" :src="asset.thumbnailUrl" alt="" width="60" class="ml-4" />
                                                            <v-chip                                                                
                                                                class="mr-4 ml-4"
                                                                color="primary"
                                                                small                                                                
                                                            >
                                                                {{getSelectLabel(assetTypeOptions, asset.assetType)}}
                                                            </v-chip>
                                                            <h6
                                                                class="ma-0 ml-4"                                                            
                                                            >
                                                                {{asset.assetName}}
                                                            </h6>
                                                        </div>
                                                        <div class="">
                                                            <div>
                                                                <v-btn class="ml-2" @click="previewAsset(asset)" small>                                                                            
                                                                        Preview
                                                                </v-btn>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{on, attrs}">
                                                                        <v-btn icon class="ml-2" 
                                                                            @click="copyAssetUrl(asset)"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon>mdi-content-copy</v-icon>
                                                                        </v-btn>                                                                        
                                                                    </template>
                                                                    <span>Copy Asset Url</span>
                                                                </v-tooltip>
                                                                
                                                                <v-btn icon class="ml-2" @click="editAsset(asset)">
                                                                    <v-icon>mdi-pencil-box-outline</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr v-if="!asset.editMode" style="border-top: 1px solid lightgray;" />                                                
                                                    <v-card v-if="asset.editMode" class="mt-4">
                                                        <v-card-title>Asset #{{index+1}}</v-card-title>
                                                        <v-card-text class="pb-0">
                                                            <v-row>                                                        
                                                                <v-col cols="12" md="8">
                                                                    <div v-if="!asset.isExternal" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                                                        <div v-if="asset.uploadResult && asset.uploadResult.success" style="display: flex; width: 100%; align-items: center;" class="pl-4">
                                                                            <div>
                                                                                <v-img v-if="asset.assetType !== 'htmlbundle' && !asset.isExternal" width="60" :src="asset.thumbnailUrl"></v-img>
                                                                                <v-img v-if="asset.assetType === 'htmlbundle'  && !asset.isExternal" width="60" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg"></v-img>                                                                        
                                                                            </div>
                                                                            <div class="pl-4">
                                                                                <v-btn @click="clearAsset(asset)" small outlined>   
                                                                                        <v-icon class="pr-2">mdi-trash-can-outline</v-icon>
                                                                                        Clear
                                                                                </v-btn>
                                                                            </div>                                                                    
                                                                        </div>                                                                 
                                                                        <div v-if="!(asset.uploadResult && asset.uploadResult.success)" style="width: 100%;">
                                                                            <v-file-input 
                                                                                type="file"
                                                                                enctype="multipart/form-data"
                                                                                name="assetfile"
                                                                                show-size 
                                                                                outlined 
                                                                                @change="(file) => { uploadFile(file, index) }"
                                                                                label="Click Here to upload a file"
                                                                                truncate-length="150"
                                                                                accept=".zip,.mp4,.mov,.mkv,.png,.gif,.jpeg,.jpg"
                                                                                >
                                                                            </v-file-input>
                                                                        </div>
                                                                        <div style="min-height: 4px; display: flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                                                            <!-- <p class="mt-0 mb-0 pt-0 pb-0" style="color: green" v-if="asset.uploadResult && asset.uploadResult.success" >Asset Upload Completed Successfully</p> -->
                                                                            <p class="mt-0 mb-0 pt-0 pb-0" style="color: green" v-if="asset.uploadProgress != null && asset.uploadProgress < 100" >Uploading {{asset.uploadProgress}}%</p>
                                                                            <v-progress-linear
                                                                                v-if="asset.uploadProgress != null && asset.uploadProgress < 100" 
                                                                                v-model="asset.uploadProgress"
                                                                                color="green"
                                                                                :active="true"   
                                                                                stream                                                                     
                                                                            />
                                                                            <p class="mt-0 mb-0 pt-0 pb-0" style="color: green" v-if="asset.uploadProgress == 100">
                                                                                <v-progress-circular
                                                                                    :size="16"
                                                                                    :width="3"
                                                                                    color="green"
                                                                                    indeterminate
                                                                                    style="margin-top: -3px;"
                                                                                ></v-progress-circular>
                                                                                Analyzing Asset... Please wait. (Estimated Time Left: {{asset.analysisEstSecondsLeft}} seconds)
                                                                            </p>
                                                                            <v-progress-linear v-if="asset.uploadProgress == 100"
                                                                                value="0"
                                                                                buffer-value="0"
                                                                                color="green"                                                                                                                                               
                                                                                stream                                                     
                                                                            />
                                                                        </div>                                                  
                                                                        <p v-if="!asset.uploadResult.success && asset.uploadProgress == null" class="mt-0 mb-0 pt-0 pb-0" >or</p>                                                            
                                                                        <v-btn v-if="!asset.uploadResult.success && asset.uploadProgress == null" dark color="secondary" style="min-width: 50%;" small>
                                                                                <v-icon class="pr-2">mdi-upload</v-icon>
                                                                                Load from Asset Library
                                                                        </v-btn>                                                                
                                                                    </div>    
                                                                    <div v-if="asset.isExternal" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                                                        <div style="display: flex; width: 100%; align-items: center;" class="pl-4">
                                                                            <div>                                                                        
                                                                                <v-img width="60" src="@/assets/images/file-types/External_60x60.jpg"></v-img>
                                                                            </div>
                                                                            <div class="pl-4">

                                                                            </div>                                                                    
                                                                        </div>
                                                                    </div>                                                        
                                                                </v-col>                                                
                                                                <v-col cols="12" md="2">
                                                                    <v-select                                                                                                                                             
                                                                        v-model="asset.assetType"
                                                                        :items="assetTypeOptions"
                                                                        label="Asset Type"
                                                                        :rules="[(value)=> externalAssetSaveValidation(value, asset, 'assetType')]"
                                                                        :validate-on-blur="false"   
                                                                        :disabled="asset.uploadResult.success && !asset.isExternal"                                                             
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="2">
                                                                    <v-switch
                                                                        v-model="asset.isExternal"
                                                                        label="External Asset"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>                                                        
                                                                <v-col cols="12" >
                                                                    <v-text-field
                                                                        v-model="asset.assetUrl"                                                                
                                                                        :validate-on-blur="false" 
                                                                        label="Asset Source Url"
                                                                        prepend-icon="mdi-content-copy"
                                                                        append-outer-icon="mdi-content-copy"
                                                                        @click:prepend="copyAssetUrl(asset)"
                                                                        :readonly="!asset.isExternal"
                                                                        :rules="[(value)=> externalAssetSaveValidation(value, asset, 'assetUrl')]"
                                                                    >
                                                                        <template v-slot:append-outer>
                                                                            <v-btn @click="previewAsset(asset)" small>                                                                            
                                                                                    Preview
                                                                            </v-btn>
                                                                        </template>
                                                                    </v-text-field>
                                                                </v-col>                                                                                                        
                                                            </v-row>
                                                            <v-row>                                                        
                                                                <v-col cols="12" md="8">
                                                                    <v-text-field
                                                                        v-model="asset.assetName"                                                                        
                                                                        :validate-on-blur="false" 
                                                                        label="Asset Name"
                                                                        :rules="[(value)=> externalAssetSaveValidation(value, asset, 'assetName')]"
                                                                        :disabled="!asset.isExternal"
                                                                    />
                                                                </v-col>                                                
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field
                                                                        v-model="asset.duration"                                                                                                                                
                                                                        label="Duration (Seconds)"
                                                                        :disabled="!asset.isExternal"
                                                                    />  
                                                                </v-col>
                                                            </v-row>                                                    
                                                            <v-row>                                                        
                                                                <v-col cols="12" md="8">
                                                                    <v-text-field
                                                                        v-show="!asset.isExternal"                                                                
                                                                        v-model="asset.storagePath"                                                                                                                                
                                                                        label="Storage Path"      
                                                                        disabled                                                                
                                                                    />
                                                                </v-col>                                                
                                                                <v-col cols="12" md="4">
                                                                    <v-select             
                                                                        v-show="!asset.isExternal"                                                        
                                                                        v-model="asset.storageTarget"
                                                                        :items="storageTargetOptions"
                                                                        label="Target Storage"  
                                                                        disabled                                                                    
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" class="d-flex justify-center">
                                                                    <v-btn dark color="primary" style="min-width: 50%;" @click="saveAsset(asset)">
                                                                            <v-icon class="pr-2">mdi-content-save</v-icon>
                                                                            Save Asset
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>                                                                                          
                                                </div>                                            
                                            </v-form>
                                            <v-btn dark color="primary" class="mt-2" style="width: 100%;" @click="addAsset">
                                                    <v-icon class="pr-2">mdi-plus-box</v-icon>
                                                    Add An Asset
                                            </v-btn>
                                                                                  
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item  transition="false" :eager="true">
                                    <v-card flat>
                                        <v-card-text>
                                            <p>
                                                Configure the delivery rules for this Campaign. These will be used to display the campaign content ads on the targeted networks.
                                                <br/>
                                                There should be at least one rule per targeted Network.
                                            </p>                                            
                                            <v-form v-model="deliveryRuleFormValid" ref="deliveryRuleForm">
                                                <div v-for="(deliveryRule, idxx) in deliveryrules" :key="deliveryRule.code">                                                    
                                                    <div v-if="!deliveryRule.editMode"
                                                        class="eg-todo-item d-flex justify-space-between flex-wrap align-center white pa-4 rounded"
                                                    >
                                                        <table class="w-full">
                                                            <colgroup>
                                                                <col style="width: 5%" />
                                                                <col style="width: 10%" />
                                                                <col style="width: 30%" />
                                                                <col style="width: 10%" />
                                                                <col style="width: 10%" />
                                                                <col style="width: 10%" />
                                                                <col style="width: 10%" />
                                                                <col style="width: 5%" />
                                                                <col style="width: 10%" />
                                                            </colgroup>
                                                            <thead class="pb-4" v-if="idxx == 0">
                                                                <tr style="font-weight: bold;" class="pb-4">
                                                                    <td>No.</td>
                                                                    <td>Network</td>
                                                                    <td>Asset</td>
                                                                    <td>Adpoints</td>
                                                                    <td>Goal</td>
                                                                    <td>Start</td>
                                                                    <td>End</td>
                                                                    <td>Daily Cap</td>
                                                                    <td></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>#{{idxx + 1}}</td>
                                                                    <td>{{ getSelectLabel(networkOptions, deliveryRule.network)}}</td>
                                                                    <td>
                                                                        <div v-if="deliveryRule.asset != null" class="d-flex align-center">
                                                                            <img v-if="getThumbnailType(getAssetById(deliveryRule.asset)) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(getAssetById(deliveryRule.asset)) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(getAssetById(deliveryRule.asset)) === 'normal'" :src="getAssetById(deliveryRule.asset).thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, getAssetById(deliveryRule.asset).assetType)}}
                                                                            </v-chip>
                                                                            <span
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{getAssetById(deliveryRule.asset).assetName}}
                                                                            </span>
                                                                        </div>                                                                          
                                                                    </td>
                                                                    <td>{{deliveryRule.totalAdpoints}}</td>
                                                                    <td>{{deliveryRule.eventAmountGoal + " " + (deliveryRule.eventType === 'customevent' ? '"' + deliveryRule.customEventName + '" Events' : getSelectLabel(eventTypeOptions, deliveryRule.eventType))   }}</td>
                                                                    <td>{{  (deliveryRule.startdate ? formatDate((new Date(deliveryRule.startdate)).toISOString()) : '') + " " +  (deliveryRule.starttime || '') }}</td>
                                                                    <td>{{  (deliveryRule.enddate ? formatDate((new Date(deliveryRule.enddate)).toISOString()) : '') + " " +  (deliveryRule.endtime || '')}}</td>                                                                    
                                                                    <td>{{deliveryRule.dailyFrequencyCap}}</td>
                                                                    <td>
                                                                        <v-btn icon class="ml-2" @click="deleteDeliveryRule(idxx)">
                                                                            <v-icon>mdi-trash-can-outline</v-icon>
                                                                        </v-btn>
                                                                        <v-btn icon class="ml-2" @click="editDeliveryRule(deliveryRule, idxx)">
                                                                            <v-icon>mdi-pencil-box-outline</v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>                                                                    
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    <hr v-if="!deliveryRule.editMode" style="border-top: 1px solid lightgray;" />
                                                    <v-card v-if="deliveryRule.editMode" class="mt-4">
                                                        <v-card-title>
                                                            <span>Delivery Rule #{{idxx+1}} </span>
                                                            <v-btn @click="deleteDeliveryRule(idxx)" small outlined class="ml-4">   
                                                                    <v-icon class="pr-2">mdi-trash-can-outline</v-icon>
                                                                    Delete
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text class="pb-0">
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-select
                                                                        :items="selectedNetworkOptions"
                                                                        v-model="deliveryRule.network"
                                                                        label="Network"
                                                                        :rules="requiredRules"
                                                                        dense
                                                                        outlined
                                                                        @change="onCompleteDeliveryRuleCondition(idxx)"
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="4">  
                                                                    <base-card>
                                                                        <v-list-item three-line>
                                                                            <v-list-item-content>
                                                                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                                                                <div class="body-2 mb-0 text--disabled">
                                                                                    Total
                                                                                </div>
                                                                                <v-list-item-title
                                                                                    class="headline mb-1 font-weight-bold"
                                                                                    >
                                                                                    {{ (deliveryRule.totalAdpoints == null || deliveryRule.calcInProgress ? '-' : deliveryRule.totalAdpoints) }}                                                                                    
                                                                                    </v-list-item-title
                                                                                >
                                                                                <v-list-item-subtitle>
                                                                                    <div class="d-flex flex-wrap">
                                                                                        <span class="font-weight-bold text-info"
                                                                                            >Targeted Adpoints  {{ (deliveryRule.calcInProgress ? '(Calculating...)' : '')}}</span
                                                                                        >
                                                                                    </div>
                                                                                </v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-progress-linear
                                                                            :active="deliveryRule.calcInProgress"
                                                                            :indeterminate="deliveryRule.calcInProgress"
                                                                            absolute
                                                                            bottom
                                                                            color="deep-purple accent-4"
                                                                        />
                                                                    </base-card>                                                                  
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <base-card>
                                                                        <v-list-item three-line>
                                                                            <v-list-item-content>
                                                                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                                                                <div class="body-2 mb-0 text--disabled">
                                                                                    Available Capacity {{ (deliveryRule.capacityCalcInProgress ? '(Calculating...)' : '')}}
                                                                                </div>
                                                                                <v-list-item-title class="headline mb-1 font-weight-bold">                                                                                         
                                                                                        <span >{{ (deliveryRule.capacityCalcInProgress || deliveryRule.availableCapacity == null ? '-' : formatInteger(deliveryRule.availableCapacity)) }} Impressions</span>
                                                                                </v-list-item-title>
                                                                                <v-list-item-subtitle>
                                                                                    <div class="d-flex flex-wrap">                                                                                                                                                                                
                                                                                        <span class="font-weight-bold text-info">
                                                                                            Out of {{ (deliveryRule.capacityCalcInProgress || deliveryRule.totalCapacity == null ? '-' : formatInteger(deliveryRule.totalCapacity)) }} 
                                                                                        </span>
                                                                                    </div>
                                                                                </v-list-item-subtitle>
                                                                            </v-list-item-content>                                                                            

                                                                            <apexchart v-if="!deliveryRule.capacityCalcInProgress && deliveryRule.availableCapacity != null && deliveryRule.totalCapacity != null && deliveryRule.totalCapacity != 0"
                                                                                width="80"
                                                                                height="110"
                                                                                type="radialBar"
                                                                                :options="chartOptions"
                                                                                :series="[ Math.ceil((deliveryRule.availableCapacity / deliveryRule.totalCapacity) * 100)]"
                                                                            />
                                                                        </v-list-item>
                                                                        <v-progress-linear
                                                                            :active="deliveryRule.capacityCalcInProgress"
                                                                            :indeterminate="deliveryRule.capacityCalcInProgress"
                                                                            absolute
                                                                            bottom
                                                                            color="deep-purple accent-4"
                                                                        />
                                                                    </base-card>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-select
                                                                        :items="assets"
                                                                        v-model="deliveryRule.asset"
                                                                        item-text="assetName"
                                                                        item-value="id"
                                                                        label="Asset"
                                                                        dense
                                                                        outlined
                                                                        :rules="requiredRules"
                                                                        @change="onCompleteDeliveryRuleCondition(idxx)"
                                                                    >
                                                                        <template v-slot:selection="data">                                                                            
                                                                            <img v-if="getThumbnailType(data.item) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'normal'" :src="data.item.thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, data.item.assetType)}}
                                                                            </v-chip>
                                                                            <h6
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{data.item.assetName}}
                                                                            </h6>                                                                            
                                                                        </template>
                                                                        <template v-slot:item="data">                                                                            
                                                                            <img v-if="getThumbnailType(data.item) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'normal'" :src="data.item.thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, data.item.assetType)}}
                                                                            </v-chip>
                                                                            <h6
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{data.item.assetName}}
                                                                            </h6>                                                                            
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="4">    
                                                                    <base-card>
                                                                        <v-list-item three-line>
                                                                            <v-list-item-content>
                                                                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                                                                <div class="body-2 mb-0 text--disabled">
                                                                                    Duration
                                                                                </div>
                                                                                <v-list-item-title
                                                                                    class="headline mb-1 font-weight-bold"
                                                                                    >
                                                                                    {{ (deliveryRule.calc_duration_days && !deliveryRule.calcInProgress ? deliveryRule.calc_duration_days : '-') }}
                                                                                    </v-list-item-title
                                                                                >
                                                                                <v-list-item-subtitle>
                                                                                    <div class="d-flex flex-wrap">
                                                                                        <span class="font-weight-bold text-info"
                                                                                            >Days {{ (deliveryRule.calcInProgress ? '(Calculating...)' : '')}}</span
                                                                                        >
                                                                                    </div>
                                                                                </v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-progress-linear
                                                                            :active="deliveryRule.calcInProgress"
                                                                            :indeterminate="deliveryRule.calcInProgress"
                                                                            absolute
                                                                            bottom
                                                                            color="deep-purple accent-4"
                                                                        />
                                                                    </base-card>                                                                
                                                                </v-col>
                                                                <v-col cols="12" md="4">                                                                     
                                                                    <base-card>
                                                                        <v-list-item three-line>
                                                                            <v-list-item-content>
                                                                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                                                                <div class="body-2 mb-0 text--disabled">
                                                                                     Estimated Avg
                                                                                </div>                                                                                                                                                                
                                                                                <v-list-item-title class="headline mb-1 font-weight-bold">
                                                                                    {{ (deliveryRule.network && !deliveryRule.calcInProgress ?  formatInteger(deliveryRule.calc_event_target_daily_total) : '-') }} {{(deliveryRule.eventType === 'customevent' ? '"' + deliveryRule.customEventName + '" Events' : getSelectLabel(eventTypeOptions, deliveryRule.eventType))   }}
                                                                                    <span  style="display: none;">{{ (deliveryRule.totalAdpoints == null ? '-' : deliveryRule.totalAdpoints) }} {{(deliveryRule.eventType === 'customevent' ? '"' + (deliveryRule.customEventName ? deliveryRule.customEventName : '') + '" Events' : getSelectLabel(eventTypeOptions, deliveryRule.eventType))   }}</span>
                                                                                </v-list-item-title>
                                                                                <v-list-item-subtitle>
                                                                                    <div class="d-flex flex-wrap">
                                                                                        <span class="font-weight-bold text-info">
                                                                                            Daily Target {{ (deliveryRule.calcInProgress ? '(Calculating...)' : '')}}
                                                                                        </span>
                                                                                    </div>
                                                                                </v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-progress-linear
                                                                            :active="deliveryRule.calcInProgress"
                                                                            :indeterminate="deliveryRule.calcInProgress"
                                                                            absolute
                                                                            bottom
                                                                            color="deep-purple accent-4"
                                                                        />
                                                                    </base-card> 
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="12">                 
                                                                        <v-alert type="warning" v-if="deliveryRule.warninmsg">
                                                                            {{deliveryRule.warninmsg}}
                                                                        </v-alert>                                                   
                                                                        <v-tabs>
                                                                            <v-tab>
                                                                                Filtering 
                                                                                <v-icon class="pl-2" v-if="!deliveryRule.filteringTabValid">mdi-alert-circle</v-icon>
                                                                            </v-tab>                                                                            
                                                                            <v-tab>
                                                                                Goal
                                                                                <v-icon class="pl-2" v-if="!deliveryRule.goalTabValid">mdi-alert-circle</v-icon>
                                                                            </v-tab>
                                                                            <v-tab>
                                                                                Analysis                                                                                
                                                                            </v-tab>
                                                                            <v-tab-item transition="true">                                                                                
                                                                                <v-alert type="error" class="mt-2 mb-0" v-if="deliveryRule.errormsg">
                                                                                    {{deliveryRule.errormsg}}
                                                                                </v-alert>
                                                                                <advanced-grid-filters-list 
                                                                                    :dynamic="true"                         
                                                                                    :horizontalRendering="true"                                                             
                                                                                    v-model="deliveryRule.filterfields" 
                                                                                    @search="onSearch" 
                                                                                    :searchButtonVisible="false"                                                                                     
                                                                                    @delete="({index, nameToDelete}) => {onDeleteCondition(idxx, {orIndex: index, nameToDelete}); }" 
                                                                                    @deleteor="(orIndex) => {onDeleteOrCondition(idxx, orIndex);}" 
                                                                                    :ref="(el) => { advancedfilter[idxx]; }"
                                                                                    @addcriteria="({attributeToAdd, orClauseIndex}) => { onAddDeliveryRuleFiltCondition(idxx, {attributeToAdd, orClauseIndex}) }"
                                                                                    :attributeOptions="attributeOptions"
                                                                                    @complete="(e)=>{onCompleteDeliveryRuleCondition(idxx)}"
                                                                                />
                                                                                <v-btn class="d-flex mt-2" style="width: 100%;" small color="black" dark @click="() => {addOrClause(idxx);}" >
                                                                                    Add "OR" Cond.
                                                                                </v-btn>   
                                                                            </v-tab-item>
                                                                            <v-tab-item transition="true" :eager="true">
                                                                                <v-row>
                                                                                    <v-col cols="12" md="4">
                                                                                        <v-row>
                                                                                            <v-col cols="12">
                                                                                                <v-select                                                                     
                                                                                                    v-model="deliveryRule.eventType"
                                                                                                    :items="eventTypeOptions"                 
                                                                                                    label="Event Type"                                             
                                                                                                    :rules="requiredRules"
                                                                                                    :validate-on-blur="false" 
                                                                                                    :clearable="true"                      
                                                                                                />
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row>
                                                                                            <v-col cols="6">
                                                                                                <DatepickerPopup 
                                                                                                    v-model="deliveryRule.startdate"
                                                                                                    label="Start Date"
                                                                                                    :rules="requiredRules"                                                                                                    
                                                                                                    @input="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                ></DatepickerPopup>
                                                                                            </v-col>
                                                                                            
                                                                                            <v-col cols="6">
                                                                                                <DatepickerPopup 
                                                                                                    v-model="deliveryRule.enddate"
                                                                                                    label="End Date"
                                                                                                    :rules="[...requiredRules, v=> endDateLaterThanStart(v, deliveryRule.endtime, deliveryRule.startdate, deliveryRule.starttime)]"
                                                                                                    @input="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                ></DatepickerPopup>
                                                                                            </v-col>
                                                                                            
                                                                                        </v-row>
                                                                                        <v-row>
                                                                                            <v-col cols="6">
                                                                                                <TimepickerPopup 
                                                                                                    v-model="deliveryRule.starttime"
                                                                                                    label="Start Time"
                                                                                                    :rules="requiredRules"
                                                                                                    @input="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                ></TimepickerPopup>
                                                                                            </v-col>
                                                                                            <v-col cols="6">
                                                                                                <TimepickerPopup 
                                                                                                    v-model="deliveryRule.endtime"
                                                                                                    label="End Time"
                                                                                                    :rules="[...requiredRules, v=> endTimeLaterThanStart(v, deliveryRule.enddate, deliveryRule.startdate, deliveryRule.starttime)]"
                                                                                                    @input="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                ></TimepickerPopup>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" md="4">
                                                                                        <v-row>
                                                                                            <v-col cols="12">
                                                                                                <v-text-field
                                                                                                    v-model="deliveryRule.customEventName"
                                                                                                    :rules="[...(deliveryRule.eventType === 'customevent' ? requiredRules : [])]"
                                                                                                    :validate-on-blur="false" 
                                                                                                    label="Custom Event Name"
                                                                                                />
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row>
                                                                                            <v-col cols="12">
                                                                                                <v-text-field
                                                                                                    type="number"
                                                                                                    v-model.number="deliveryRule.eventAmountGoal"
                                                                                                    :rules="requiredRules"
                                                                                                    :validate-on-blur="false" 
                                                                                                    label="Event Amount Goal"
                                                                                                    @change="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                />
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row>
                                                                                            <v-col cols="12">
                                                                                                <v-text-field
                                                                                                    type="number"
                                                                                                    v-model.number="deliveryRule.dailyFrequencyCap"
                                                                                                    :rules="numberRequiredRules"
                                                                                                    :validate-on-blur="false" 
                                                                                                    label="Daily Frequency Cap (Per Adpoint - Zero [0] means No Cap)"
                                                                                                    @change="onCompleteDeliveryRuleCondition(idxx)"
                                                                                                />
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" md="4">
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-tab-item>
                                                                            <v-tab-item transition="true">  
                                                                                <!-- <v-card-title class="text-center" style="color: black;">Non-operational Adpoint Count Day </v-card-title>
                                                                                <v-btn class="ma-2" dark color="success" :loading="loading" @click="fetchRuleAnalysisData(idxx)">                                                                                    
                                                                                    Calculate
                                                                                </v-btn>    -->
                                                                                <div class="d-flex justify-center mt-2" style="width: 100%">
                                                                                    <v-btn dark color="primary" @click="calculateDeliveryRuleAnalytics(deliveryRule, idxx)">
                                                                                        <v-icon>mdi-reload</v-icon>
                                                                                        Recalculate Rule
                                                                                    </v-btn>
                                                                                </div>                                                                                
                                                                                <div v-if="pollingScenarioStatus=='started' || pollingScenarioInProgress==1" class="justify-center align-center" style="font-size: 1.5em;">
                                                                                    <base-card>
                                                                                        <!-- <v-card-title class="text-center">Calculating... </v-card-title> -->
                                                                                        <v-card-text>
                                                                                            <!-- <div v-if="analysisdata && analysisdata.mustSave==1" class="text-center">
                                                                                                <div style="font-size: 1.5em">Please save Delivery Rule to view Analysis</div>
                                                                                            </div>
                                                                                            <div v-else class="text-center">
                                                                                                <div>Calculating...</div>
                                                                                                <v-progress-circular
                                                                                                    :rotate="-90"
                                                                                                    :size="100"
                                                                                                    :width="15"
                                                                                                    :value="pollingScenarioProgress"
                                                                                                    color="primary"
                                                                                                >
                                                                                                    {{ pollingScenarioProgress }}%
                                                                                                </v-progress-circular>
                                                                                            </div>-->

                                                                                            <div class="text-center">
                                                                                                <div>Calculating...</div>
                                                                                                <v-progress-circular
                                                                                                    :rotate="-90"
                                                                                                    :size="100"
                                                                                                    :width="15"
                                                                                                    :value="pollingScenarioProgress"
                                                                                                    color="primary"
                                                                                                >
                                                                                                    {{ pollingScenarioProgress }}%
                                                                                                </v-progress-circular>
                                                                                            </div>  
                                                                                        </v-card-text>
                                                                                        <v-progress-linear
                                                                                            :active="true"
                                                                                            :indeterminate="true"
                                                                                            absolute
                                                                                            bottom
                                                                                            color="red accent-4"
                                                                                        />
                                                                                    </base-card>                                                                                    
                                                                                </div>
                                                                                <div v-else>                                                                                    
                                                                                    <chart-card title="Estimated Capacity Distribution Per Day">
                                                                                        <template slot="chart">
                                                                                        <div v-if="analysisdata && deliveryRule.network && deliveryRule.startdate && deliveryRule.enddate" id="basicArea-chart" style="min-height: 365px">
                                                                                            <apexchart
                                                                                            type="bar"
                                                                                            height="350"
                                                                                            :options="getCapacityDistributionPerDayData(getDurationInDays(deliveryRule), deliveryRule.startdate, deliveryRule.enddate, analysisdata).chartOptions"
                                                                                            :series="getCapacityDistributionPerDayData(getDurationInDays(deliveryRule), deliveryRule.startdate, deliveryRule.enddate, analysisdata).series"
                                                                                            />
                                                                                        </div>
                                                                                        </template>
                                                                                    </chart-card>
                                                                                </div>

                                                                            </v-tab-item>
                                                                        </v-tabs>                                                                                                                                    
                                                                </v-col>
                                                                
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" class="d-flex justify-center">
                                                                    <v-btn dark color="primary" style="min-width: 50%;" @click="saveDeliveryRule(deliveryRule)">
                                                                            <v-icon class="pr-2">mdi-content-save</v-icon>
                                                                            Save Rule
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                            </v-form>
                                            <v-btn dark color="primary" class="mt-2" style="width: 100%;" @click="addDeliveryRule">
                                                    <v-icon class="pr-2">mdi-plus-box</v-icon>
                                                    Add A Delivery Rule
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item transition="false" :eager="true">
                                    <v-card flat>
                                        <v-card-text>
                                            <p>
                                                Here you can configure the budget for the Campaign. When AT LEAST ONE of budget limits are met, the campaign is completed and its delivery stops.
                                                <br/>
                                                Each rule is assigned to a specific asset & a single event. This event must be one of the events that are defined on previous 'Delivery' tab.
                                            </p>
                                            <v-form v-model="budgetRuleFormValid" ref="budgetRuleForm">
                                                <div v-for="(budgetRule, index) in budgetrules" :key="budgetRule.code">
                                                    <div v-if="!budgetRule.editMode"
                                                        class="eg-todo-item d-flex justify-space-between flex-wrap align-center white pa-4 rounded"
                                                    >
                                                        <table class="w-full">
                                                            <colgroup>
                                                                <col style="width: 5%" />                                                                
                                                                <col style="width: 30%" />
                                                                <col style="width: 15%" />
                                                                <col style="width: 12%" />
                                                                <col style="width: 12%" />
                                                                <col style="width: 12%" />
                                                                <col style="width: 14%" />
                                                            </colgroup>
                                                            <thead class="pb-4" v-if="index == 0">
                                                                <tr style="font-weight: bold;" class="pb-4">
                                                                    <td>No.</td>
                                                                    <td>Asset</td>
                                                                    <td>Event</td>
                                                                    <td>Type</td>
                                                                    <td>Event Cost</td>
                                                                    <td>Budget</td>                                                                    
                                                                    <td></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>#{{index + 1}}</td>
                                                                    <td>
                                                                        <div v-if="budgetRule.asset != null" class="d-flex align-center">
                                                                            <img v-if="getThumbnailType(getAssetById(budgetRule.asset)) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(getAssetById(budgetRule.asset)) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(getAssetById(budgetRule.asset)) === 'normal'" :src="getAssetById(budgetRule.asset).thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, getAssetById(budgetRule.asset).assetType)}}
                                                                            </v-chip>
                                                                            <span
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{getAssetById(budgetRule.asset).assetName}}
                                                                            </span>
                                                                        </div>                                                                          
                                                                    </td>
                                                                    <td>
                                                                        {{ (budgetRule.eventType === 'customevent' ? '"' + budgetRule.customEventName + '"' : 'Impressions') }}
                                                                    </td>
                                                                    <td>{{getSelectLabel(budgetCostTypeOptions ,budgetRule.costType)}}</td>
                                                                    <td>{{budgetRule.eventCost}}</td>
                                                                    <td>{{budgetRule.budget}}</td>
                                                                    <td>
                                                                        <v-btn icon class="ml-2" @click="deleteBudgetRule(budgetRule)">
                                                                            <v-icon>mdi-trash-can-outline</v-icon>
                                                                        </v-btn>
                                                                        <v-btn icon class="ml-2" @click="editBudgetRule(budgetRule)">
                                                                            <v-icon>mdi-pencil-box-outline</v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <hr v-if="!budgetRule.editMode" style="border-top: 1px solid lightgray;" />
                                                    <v-card v-if="budgetRule.editMode" class="mt-4">
                                                        <v-card-title>
                                                            <span>Budget Rule #{{index+1}} </span>
                                                            <v-btn @click="deleteBudgetRule(budgetRule)" small outlined class="ml-4">   
                                                                    <v-icon class="pr-2">mdi-trash-can-outline</v-icon>
                                                                    Delete
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text class="pb-0">
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-select
                                                                        :items="assets"
                                                                        v-model="budgetRule.asset"
                                                                        item-text="assetName"
                                                                        item-value="id"
                                                                        label="Asset"
                                                                        dense
                                                                        outlined
                                                                        :rules="requiredRules"
                                                                    >
                                                                        <template v-slot:selection="data">                                                                            
                                                                            <img v-if="getThumbnailType(data.item) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'normal'" :src="data.item.thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, data.item.assetType)}}
                                                                            </v-chip>
                                                                            <h6
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{data.item.assetName}}
                                                                            </h6>                                                                            
                                                                        </template>
                                                                        <template v-slot:item="data">                                                                            
                                                                            <img v-if="getThumbnailType(data.item) === 'external'" src="@/assets/images/file-types/External_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'htmlbundle'" src="@/assets/images/file-types/HtmlZip_Icon_60x60.jpg" alt="" width="60" class="ml-4" />
                                                                            <img v-if="getThumbnailType(data.item) === 'normal'" :src="data.item.thumbnailUrl" alt="" width="60" class="ml-4" />
                                                                            <v-chip                                                                
                                                                                class="mr-4 ml-4"
                                                                                color="primary"
                                                                                small                                                                
                                                                            >
                                                                                {{getSelectLabel(assetTypeOptions, data.item.assetType)}}
                                                                            </v-chip>
                                                                            <h6
                                                                                class="ma-0 ml-4"                                                            
                                                                            >
                                                                                {{data.item.assetName}}
                                                                            </h6>                                                                            
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="4">                                                                    
                                                                </v-col>
                                                                <v-col cols="12" md="4">                                                                                                                                         
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="3">
                                                                    <v-select                                                                     
                                                                        v-model="budgetRule.eventType"
                                                                        :items="eventTypeOptions"                 
                                                                        label="Event Type"                                             
                                                                        :rules="requiredRules"
                                                                        :validate-on-blur="false" 
                                                                        :clearable="true"                      
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="3">
                                                                    <v-select                                                                     
                                                                        v-model="budgetRule.customEventName"
                                                                        :items="getBudgetCustomEventOptions(budgetRule)"                 
                                                                        label="Custom Event Name"                                             
                                                                        :rules="[...(budgetRule.eventType === 'customevent' ? requiredRules : [])]"
                                                                        :validate-on-blur="false" 
                                                                        :clearable="true"                      
                                                                    />                                                                    
                                                                </v-col>
                                                                <v-col cols="12" md="6">

                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="3">                                                                    
                                                                    <v-text-field
                                                                        type="number"
                                                                        v-model.number="budgetRule.eventCost"
                                                                        :rules="requiredRules"
                                                                        :validate-on-blur="false" 
                                                                        label="Event Cost"
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="3">
                                                                    <v-select                                                                     
                                                                        v-model="budgetRule.costType"
                                                                        :items="budgetCostTypeOptions"                 
                                                                        label="Cost Type"                                             
                                                                        :rules="requiredRules"
                                                                        :validate-on-blur="false" 
                                                                        :clearable="true"                      
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="6">

                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="3">
                                                                    <v-text-field
                                                                        type="number"
                                                                        v-model.number="budgetRule.budget"
                                                                        :rules="requiredRules"
                                                                        :validate-on-blur="false" 
                                                                        label="Budget"
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" md="3">

                                                                </v-col>
                                                                <v-col cols="12" md="6">

                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" class="d-flex justify-center">
                                                                    <v-btn dark color="primary" style="min-width: 50%;" @click="saveBudgetRule(budgetRule)">
                                                                            <v-icon class="pr-2">mdi-content-save</v-icon>
                                                                            Save Rule
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                            </v-form>
                                            <v-btn dark color="primary" class="mt-2" style="width: 100%;" @click="addBudgetRule">
                                                    <v-icon class="pr-2">mdi-plus-box</v-icon>
                                                    Add A Budget Rule
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item transition="false">
                                    <v-card flat>
                                        <v-card-text>
                                            <p>
                                                This is an overview of the entire Campaign's configuration parameters.
                                            </p>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-card-text>
                </base-card>

            </div>
        </v-sheet>        
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            bottom
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import { api } from "src/api/index";
import { fieldNotEmpty, numberFieldNotEmpty, fieldMinLength, fieldSelectionArrayNotEmpty, endDateLaterThanStart, endTimeLaterThanStart } from "src/core/vuetify-validators.js";
import DatepickerPopup from "@/components/core/DatepickerPopup";
import TimepickerPopup from "@/components/core/TimepickerPopup";
import AdvancedGridFiltersListNew from "@/components/core/AdvancedGridFiltersListNew";
import {parseDate, formatDate, getCtrlDateTime} from '@/core/date-utils.js';
import { integerFormatting } from '@/core/number-utils.js';

import { statusCalc as sCalc } from "@/core/ui-utils.js"

import ChartCard from "@/components/card/ChartCard";

// import {
//   stackedColumn,
// } from "@/data/apexChart";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Campaign Edit'
    },
    components: {        
        DatepickerPopup,
        TimepickerPopup,
        "advanced-grid-filters-list": AdvancedGridFiltersListNew,
        ChartCard  
    },
    data() {     
        return {

            // filtercriteria: null,
            // filterfields: [],

            //demo data
            series: [72],
            chartOptions: {
                chart: {
                height: 120,
                type: "radialBar"
                },
                
            
                colors:["#33cc33"],
                plotOptions: {
                radialBar: {
                    hollow: {
                    margin: 0,
                    size: "40%"
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: false,
                        color: "green",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 6,
                        color: "#828d99",
                        fontSize: "15px",
                        show: true,
                        fontWeight: 700,
                    }
                    }
                }
                },
            
                stroke: {
                lineCap: "round",
                },
                labels: ["Progress"]
            },
            stackedColumn: {
                series: [{
                    name: 'Already Allocated',
                    data: [13, 23, 20, 8, 13, 27, 33, 12],                    
                }, {
                    name: 'Available',                    
                    data: [44, 55, 41, 67, 22, 43, 21, 49],
                }, {
                    name: 'Estimated Impact',
                    data: [11, 17, 15, 15, 21, 14, 15, 13],
                }],
                chartOptions: {
                    chart: {
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                    stackType: '100%',
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                        },
                    },
                    }],
                    xaxis: {
                    categories: ['2011 Q1', '2011 Q2', '2011 Q3', '2011 Q4', '2012 Q1', '2012 Q2',
                        '2012 Q3', '2012 Q4',
                    ],
                    },
                    fill: {
                    opacity: 1,
                    },

                    legend: {
                    position: 'top',
                    },
                },
            },











            //primary data
            status: 'draft',
            id: null,
            

            
            //first tab "Basic Info"
            campaignid: '',
            brand: '',
            description: '',
            priority: '',            
            startdate: null,
            starttime: null,
            enddate: null,
            endtime: null,
            targetnetworks: [],
            //second tab "Content"
            assets: [],
            deliveryrules: [],
            budgetrules: [],
            advancedfilter: {},

            analysisdata: null,

            // isExternal: false,
            // assetType: '',
            // assetName: '',
            // duration: '',
            // assetUrl: '',
            // storagePath: '',
            // storageTarget: '',

            //ui mechanics    
            crud_code: null,  
            createMode: false,                              
            assetCreateFormValid: true,
            deliveryRuleFormValid: true,
            budgetRuleFormValid: true,
            basicInfoFormValid: true,            
            selectedTab: null,
            previousTabIndex: null,            
            loading: false,            
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            polling: null,
            pollingScenarioStatus: 'completed',
            pollingScenarioInProgress: 0,   
            pollingScenarioRuleIndex: 0,
            pollingScenarioProgress: 100,
            pollingScenarioStatus_Previous: 'completed',
            //reference data             
            networkOptions: [],            
            brandOptions: [],
            priorityOptions: [
                1000, 900, 800, 700, 600, 500, 400, 300, 200, 100
            ],
            assetTypeOptions: [                
                {text: "Image", value: "image"},
                {text: "Video", value: "video"},
                {text: "Html Bundle", value: "htmlbundle"},                
            ],
            storageTargetOptions: [ { value: "adpointsblob", text: "Adpoints Blob Storage" } ],
            attributeOptions: [],
            eventTypeOptions: [
                {value: "impression", text: "Impressions"}, 
                {value: "customevent", text: "Custom Event"}
            ],
            budgetCostTypeOptions: [
                {value: 'cpc', text: 'CPC (Cost Per Click)'},
                {value: 'cpm', text: 'CPM (Cost Per Mille)'},
            ],
            //validation                                             
            requiredRules: [
                fieldNotEmpty                
            ],
            numberRequiredRules: [
                numberFieldNotEmpty                
            ],
            arrayRequiredRules: [
                fieldSelectionArrayNotEmpty
            ],
            endDateRequiredRules: [
                fieldNotEmpty,
                v => endDateLaterThanStart(v, this.endtime, this.startdate, this.starttime),
            ],
            endTimeRequiredRules: [
                fieldNotEmpty,
                v => endTimeLaterThanStart(v, this.enddate, this.startdate, this.starttime),
            ]
        }
    },
    computed: {
        // budgetCustomEventOptions() {            
        //     return this.deliveryrules.map((d) => {

        //     });
        // },
        selectedNetworkOptions() {
            return this.networkOptions.filter(n=> this.targetnetworks.includes(n.value))
        },
        // statusCalc() {                        
        //     if(this.status === 'released') {
        //         let startdatetime = getCtrlDateTime(this.startdate, this.starttime);
        //         if(startdatetime <= Date.now() ) {
        //             return 'running';
        //         }
        //         else {
        //             return 'queued';
        //         }
        //     }
        //     else if (this.status === 'completed') {                
        //         let enddatetime = getCtrlDateTime(this.enddate, this.endtime);
        //         if(enddatetime <= Date.now()) {
        //             return 'completed';
        //         }
        //         else {
        //             return 'stopped';
        //         }
        //     }
        //     return this.status;
        // },
        statusCalc() {
            if(this.startdate && this.starttime && this.enddate && this.endtime) {
                return sCalc({status: this.status, startdatetime: getCtrlDateTime(this.startdate, this.starttime), enddatetime: getCtrlDateTime(this.enddate, this.endtime)});
            }
            else {
                return '';
            }            
        },
        campaignBrandDescr() {     
            if(this.brand && this.brandOptions) {
                const rslt = this.brandOptions.filter(i=> this.brand === i.code);                
                if(rslt) {
                    return rslt;
                }                                           
            }                   
            return [];
        },        
        // status() {
        //     return (this.disabled) ? "Disabled" :
        //             (this.invitation_code) ? "Invited" :
        //             (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        // }
    },
    beforeDestroy () {
        clearInterval(this.polling)
    },
    async created() {
        // const rsp = await api.campaign.getInitData(); 
        // const allData = rsp.data;
        // this.networkOptions = allData.networks.map(n=> { return { value: n.code, text: n.title }});
        // this.brandOptions = allData.brands.map(n=> { return { value: n.code, text: n.title }});              

        const rsp = await api.adpoint.getInitData();
        this.networkOptions = rsp.data.networkOptions;
        this.deviceTypeOptions = rsp.data.deviceTypeOptions;
        this.brandOptions = rsp.data.brandOptions;
        this.attributeOptions = [...rsp.data.attributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}}), ...rsp.data.standardAttributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}})];
        
        if(this.$route.params.code) {            
            this.crud_code = this.$route.params.code;       
            this.createMode = false;                   
        }
        else {            
            this.createMode = true;
        }
        if(!this.createMode) {
            const resp = await api.campaign.getOne(this.crud_code);            
            this.initializeFormData(resp.data);
        } 
        //this.pollData();
        
    },
    async mounted() {           
        /*
        // const rsp = await api.brand.getAll();            
        // this.brand_list_items = rsp.data.map(i=> {return {...i, selectvalue: i.code, selecttext: i.title}});
        const rspB = await api.brand.getOne(this.crud_code);
        if(rspB && rspB.data) {
            this.code = rspB.data.code;
            this.title = rspB.data.title;
            this.description = rspB.data.description;
            this.contact_name = rspB.data.contact_name;
            this.contact_email = rspB.data.contact_email;
            this.contact_phoneno = rspB.data.contact_phoneno;            
        }
        //this.editFormValid = true;
        */
    },
    methods: {      
        formatInteger(src) {
            return integerFormatting(src);
        },
        pollData () {
            this.polling = setInterval(async () => {
                if(this.id) {
                    let rslt = await api.campaign.calcprogress();

                    //console.log("SOMETHING SOMETHING: \n" + JSON.stringify(rslt.data, null, 2));
                    if(rslt.data.scenario.hasOwnProperty(this.id.toString())) {
                        let entry = rslt.data.scenario[this.id.toString()];
                        this.pollingScenarioStatus = entry.status;
                        this.pollingScenarioProgress = (entry.status == 'completed' ? 100 : !entry.hasOwnProperty('progress') ? 0 : entry.progress );
                        if(this.pollingScenarioStatus_Previous != this.pollingScenarioStatus) {
                            if(this.pollingScenarioStatus == 'completed') {                                
                                // //TRIGGER ANALYSIS DATA RETRIEVAL
                                // console.log('----------------------------------------------------- MUST RETRIEVE ANALYSIS DATA');
                                await this.fetchRuleAnalysisData(this.pollingScenarioRuleIndex);
                                //alert(JSON.stringify(this.analysisdata));
                                this.pollingScenarioInProgress=0;
                                clearInterval(this.polling);
                            }                            
                            //UPDATE _Previous status
                            this.pollingScenarioStatus_Previous = this.pollingScenarioStatus;
                        }
                    }     
                }                           
                //set individual properties
            }, 2000)
        },
        endDateLaterThanStart(v, endTime, startDate, startTime) {
            return endDateLaterThanStart(v, endTime, startDate, startTime);
        }, 
        endTimeLaterThanStart(v, endDate, startDate, startTime) {
            return endTimeLaterThanStart(v, endDate, startDate, startTime);
        },        
        getCapacityDistributionPerDayData(totalDays, startDate, endDate, statsData)
        {    
            let initData = {
                series: [{
                    name: 'Already Allocated',
                    data: [13, 23, 20, 8, 13, 27, 33, 12],                    
                }, {
                    name: 'Available',                    
                    data: [44, 55, 41, 67, 22, 43, 21, 49],
                }, {
                    name: 'Estimated Impact',
                    data: [11, 17, 15, 15, 21, 14, 15, 13],
                }],
                chartOptions: {
                    chart: {
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                    stackType: '99%',
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                        },
                    },
                    }],
                    xaxis: {
                    categories: ['2011 Q1', '2011 Q2', '2011 Q3', '2011 Q4', '2012 Q1', '2012 Q2',
                        '2012 Q3', '2012 Q4',
                    ],
                    },
                    fill: {
                    opacity: 1,
                    },

                    legend: {
                    position: 'top',
                    },
                },
            };

            // let generatedData = {
            //     allocated: [13, 23, 20, 8, 13,      27, 33, 12, 27, 15,       27, 20, 14, 33, 15,      24, 31, 22, 15, 11,       15, 22, 19, 24, 29,      24, 31, 22, 15, 11,],
            //     available: [44, 55, 41, 67, 22,     43, 21, 49, 39, 41,       78, 71, 65, 55, 41,      43, 52, 25, 43, 61,       44, 51, 60, 48, 35,      43, 52, 25, 43, 61,],  
            //     impact:    [11, 17, 15, 15, 21,     14, 15, 13, 15, 21,       11, 17, 15, 15, 21,      14, 15, 13, 15, 21,       14, 15, 13, 15, 21,      14, 15, 13, 15, 21,],
            //     days: [...Array(Math.min(totalDays, 30)).keys()].map(i=> { return 'Day ' + i }),
            // };  
            if(statsData && statsData.impact) {
                totalDays = statsData.impact.length;

                let generatedData = {
                    allocated: statsData.impact.map(r=>r.previous_allocated_capacity_impr),
                    available: statsData.impact.map(r=>r.available_capacity_impr),  
                    impact:    statsData.impact.map(r=>r.impact_capacity_impr),
                    days: [...Array(Math.min(totalDays, 30)).keys()].map(i=> { return 'Day ' + (i+1) }),
                };  

                // let generatedData = {
                //     allocated: [13, 23, 20, 8, 13,      27, 33, 12, 27, 15,       27, 20, 14, 33, 15,      24, 31, 22, 15, 11,       15, 22, 19, 24, 29,      24, 31, 22, 15, 11,],
                //     available: [44, 55, 41, 67, 22,     43, 21, 49, 39, 41,       78, 71, 65, 55, 41,      43, 52, 25, 43, 61,       44, 51, 60, 48, 35,      43, 52, 25, 43, 61,],  
                //     impact:    [11, 17, 15, 15, 21,     14, 15, 13, 15, 21,       11, 17, 15, 15, 21,      14, 15, 13, 15, 21,       14, 15, 13, 15, 21,      14, 15, 13, 15, 21,],
                //     days: [...Array(Math.min(totalDays, 30)).keys()].map(i=> { return 'Day ' + i }),
                // };  

                generatedData.allocated.splice(totalDays);
                generatedData.available.splice(totalDays);
                generatedData.impact.splice(totalDays);
                generatedData.days.splice(totalDays);            
                initData.series[0].data = generatedData.allocated;
                initData.series[1].data = generatedData.available;
                initData.series[2].data = generatedData.impact;
                initData.chartOptions.xaxis.categories = generatedData.days;
                
                return initData;
            }
            
        },
        generateRandomInteger(min, max)  {
            const diff = max - min;            
            return Math.floor(min + (Math.random() * diff));
        },
        getDurationInDays(rule) {
            const s = new Date(rule.startdate);
            const e = new Date(rule.enddate);
            const diffTime = Math.abs(e - s);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; 
        },
        getDailyTarget(rule) {
            return Math.ceil(rule.eventAmountGoal / this.getDurationInDays(rule));
        },
        formatDate(date) {
            return formatDate(date);
        },        
        showSuccessSnackbar(message) {
            this.snackbarColor = "green";
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        showFailSnackbar(message) {
            this.snackbarColor = "red";
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        showSnackbar(message, color="orange") {
            this.snackbarColor = color;
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        initializeFormData(initData) {
            this.id = initData.id;
            this.campaignid= initData.campaignid;
            this.description= initData.description;
            this.brand= initData.brand;
            this.status = initData.status;
            this.priority= initData.priority;
            this.startdate= initData.startdatetime.substring(0, 10);
            this.starttime= initData.startdatetime.substring(11, 13) + ':' + initData.startdatetime.substring(14, 16);
            this.enddate= initData.enddatetime.substring(0, 10);
            this.endtime= initData.enddatetime.substring(11, 13) + ':' + initData.enddatetime.substring(14, 16);
            this.targetnetworks= initData.targetnetworks;
            this.assets= initData.assets;
            this.deliveryrules= initData.deliveryrules;
            //attach properties that may not exist in db stored json data...
            this.deliveryrules.forEach(dr=> {
                if(!dr.hasOwnProperty('calc_duration_days')) {
                    dr.calc_duration_days = null;
                }
                if(!dr.hasOwnProperty('calc_event_target_daily_total')) {
                    dr.calc_event_target_daily_total = null;
                }
                if(!dr.hasOwnProperty('calcInProgress')) {
                    dr.calcInProgress = false;
                }
                if(!dr.hasOwnProperty('capacityCalcInProgress')) {
                    dr.capacityCalcInProgress = false;
                }
                if(!dr.hasOwnProperty('impactCalcInProgress')) {
                    dr.impactCalcInProgress = false;
                }
                if(dr.dailyFrequencyCap == null) {
                    dr.dailyFrequencyCap = 0;
                }
            });
            this.budgetrules= initData.budgetrules;
            this.$refs.basicInfoForm.resetValidation();
        },
        getSelectLabel(optionsList, value){
            let rslt = ''; 
            const item = optionsList.find(o=>o.value === value);
            if(item) {
                rslt = item.text;
            }
            return rslt;
        },
        getBudgetCustomEventOptions(rule) {            
            let dRules = this.deliveryrules.filter(d=>d.asset===rule.asset);
            let rslt = [];
            dRules.forEach((d) => {
                if(d.customEventName) {
                    rslt.push(d.customEventName);
                }
            });
            return rslt;
        },
        getThumbnailType(asset){
            if(asset.isExternal) {
                return "external";                
            }
            else if(asset.assetType === 'htmlbundle') {
                return "htmlbundle";                
            }
            else {
                return "normal";                
            }
        },
        tabClicked_BasicInfo(e) {
            this.previousTabIndex = this.selectedTab;            
        },
        tabClicked_Content(e) {
            this.previousTabIndex = this.selectedTab;            
        },
        tabClicked_Delivery(e) {
            this.previousTabIndex = this.selectedTab;            
        },
        tabClicked_Budget(e) {
            this.previousTabIndex = this.selectedTab;            
        },
        tabClicked_Summary(e) {
            this.previousTabIndex = this.selectedTab;            
        },
        tabChanged(e) {            
            switch(this.previousTabIndex) {
                case 0: //Trying to leave 'Basic Info       
                    if(this.createMode) {
                        this.$refs.basicInfoForm.validate();
                        if(!this.basicInfoFormValid) {
                            this.$nextTick(() => {
                                this.selectedTab = this.previousTabIndex;
                            })                        
                        }
                        else {
                            this.save();
                        }
                    }                                 
                    break;
                case 1: //Trying to leave 'Content'
                    break;
                case 2: //Trying to leave 'Delivery'
                    break;
                case 3: //Trying to leave 'Budget'
                    break; 
                case 4: //Trying to leave 'Summary'
                    break;                   
            }
        },
        validateEverything(showSnackMessage=true, skipDeliveryRulesEdit=false) {
            this.$refs.basicInfoForm.validate();
            this.$refs.assetCreateForm.validate();
            this.$refs.deliveryRuleForm.validate();
            this.$refs.budgetRuleForm.validate();
            //basic info
            if(!this.basicInfoFormValid) {                
                this.showFailSnackbar("There are invalid fields on 'BASIC INFO' tab.");                                
            }
            //assets
            if(!this.assetCreateFormValid) {                
                this.showFailSnackbar("There are invalid fields on 'CONTENT' tab.");                                
            }
            else {
                this.assets.forEach(i=>i.editMode = false);
            }
            //delivery rules    
            if(!skipDeliveryRulesEdit && this.deliveryrules.find(i=>i.editMode == true)) {
                this.showFailSnackbar("Please complete/save all delivery rules that are being currently edited on 'DELIVERY' tab, before saving.");
                return false;
            }
            if(!this.deliveryRuleFormValid) {                
                this.showFailSnackbar("There are invalid fields on 'DELIVERY' tab.");                                
            }
            else {
                if(!skipDeliveryRulesEdit) {
                    this.deliveryrules.forEach(i=>i.editMode = false);
                }                
            }
            //budget
            if(this.budgetrules.find(i=>i.editMode == true)) {
                this.showFailSnackbar("Please complete/save all budget rules that are being currently edited on 'BUDGET' tab, before saving.");
                return false;
            }
            if(!this.budgetRuleFormValid) {                
                this.showFailSnackbar("There are invalid fields on 'BUDGET' tab.");                                
            }
            else {
                this.budgetrules.forEach(i=>i.editMode = false);
            }
            return this.basicInfoFormValid && this.assetCreateFormValid && this.deliveryRuleFormValid && this.budgetRuleFormValid;
        },
        resetAllValidation() {
            this.$refs.basicInfoForm.validate();
            this.$refs.assetCreateForm.resetValidation();            
            this.$refs.deliveryRuleForm.resetValidation();            
            this.$refs.budgetRuleForm.resetValidation();        
        },

        //ASSET START
        async uploadFile(file, index) {
            let formData = new FormData();
            formData.append("file", file);            
            this.assets[index].analysisEstSecondsLeft = Math.round((file.size / (1024 * 1014)) / 8) + 15;
            this.countDownTimer(index);
            this.assets[index].uploadResult.success = null;
            this.assets[index].uploadResult.message = '';
            try {
                const rsp = await api.campaign.uploadAsset(
                    formData, this.id.toString(), index, this.assets[index].id, 
                    (event) => {
                        this.assets[index].uploadProgress = Math.round((100 * event.loaded) / event.total);
                    }
                ); 
                this.$refs.assetCreateForm.resetValidation();
                this.assets[index].uploadProgress = null;
                this.assets[index].uploadResult.success = rsp.data.success;
                this.assets[index].uploadResult.message = rsp.data.message;
                if(rsp.data.success) {                                                            
                    this.assets[index].assetType = rsp.data.asset.assetType;
                    this.assets[index].assetName = rsp.data.asset.assetName;
                    this.assets[index].duration = rsp.data.asset.duration;
                    this.assets[index].assetUrl = rsp.data.asset.assetUrl;
                    this.assets[index].thumbnailUrl = rsp.data.asset.thumbnailUrl;
                    this.assets[index].storagePath = rsp.data.asset.storagePath;
                    this.assets[index].storageTarget = rsp.data.asset.storageTarget;
                }                  
            } catch (error) {
                this.assets[index].uploadSuccess = false;
                this.assets[index].uploadMesage = "Error: " + error.response.data.message;
            }            
        },
        addAsset() {
            //this.selectedTab = 0;
            if(this.assets.find(a=>a.editMode)){
                this.showFailSnackbar("Please save the asset currently being edited, before adding a new one.");
                return;
            }
            let nextIndex = this.assets.length + 1;
            let initAssetData = {
                id: this.id + '-' + nextIndex + '-' + Date.now(),
                //isNew: true,
                editMode: true,
                uploadProgress: null,                   
                analysisEstSecondsLeft: null,
                uploadResult: {
                    success: null,
                    nessage: '',
                },                
                isExternal: false,
                assetType: '',
                assetName: '',
                duration: '',
                assetUrl: '',
                thumbnailUrl: '',
                storagePath: '',
                storageTarget: '',
            }
            this.assets.push(initAssetData);            
            //console.log(this.$refs.assetType);
        },
        editAsset(asset) {
            asset.editMode = true;
        }, 
        saveAsset(asset) {
            this.$refs.assetCreateForm.validate();
            if(this.assetCreateFormValid) {
                asset.editMode = false;
            }            
        },        
        previewAsset(asset) {
            window.open(asset.assetUrl, "_blank");
        },
        clearAsset(asset) {
            asset.uploadProgress = null;
            asset.analysisEstSecondsLeft = null;
            asset.uploadResult.success = null;
            asset.uploadResult.message = '';
            asset.isExternal = false;
            asset.assetType = '';
            asset.assetName = '';
            asset.duration = '';
            asset.assetUrl = '';
            asset.thumbnailUrl = '';
            asset.storagePath = '';
            asset.storageTarget = '';
        },
        async copyAssetUrl(asset) {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(asset.assetUrl);
            }            
            else {
                console.log(asset.assetUrl);
            }
        },
        countDownTimer(index) {                
                if (this.assets[index].analysisEstSecondsLeft > 0) {
                    console.log("TIMER: " + this.assets[index].analysisEstSecondsLeft);
                    setTimeout(() => {
                        this.assets[index].analysisEstSecondsLeft -= 1
                        this.countDownTimer(index)
                    }, 1000)
                }
        },
        externalAssetSaveValidation(value, asset, fieldName) {            
            //if(asset.isExternal) {
                if(!value) {
                    
                    return fieldName === 'assetUrl' ? 'This field is Required. Please Upload a file OR point asset to an External File.' : 'This field is Required.';
                }
                if(typeof value === 'strindeliverycalcg' && value.trim() === '') {
                    return fieldName === 'assetUrl' ? 'This field is Required. Please Upload a file OR point asset to an External File.' : 'This field is Required.';
                }

            //}
            return true;
        },
        getAssetById(assetId) {
            return this.assets.find(e=>e.id === assetId);
        },
        //ASSET END

        //DELIVERY START
        async onCompleteDeliveryRuleCondition(ruleIndex) {
            if(!this.deliveryrules[ruleIndex].network) {
                return;
            }
            this.deliveryrules[ruleIndex].calcInProgress = true;
            this.deliveryrules[ruleIndex].capacityCalcInProgress = true;
            // alert(this.deliveryrules[ruleIndex].asset);
            // alert(JSON.stringify(this.assets, null, 2));            
            let rslt = await api.campaign.deliverycalc({
                campaignid: this.campaignid,
                priority: this.priority,
                assetData: this.assets.find(a=>a.id == this.deliveryrules[ruleIndex].asset),
                ruleData: this.deliveryrules[ruleIndex],
                otherRuleCodes: this.deliveryrules.map(d=>d.code)
            });
            this.deliveryrules[ruleIndex].totalAdpoints = rslt.data.calc_adpoint_total;
            this.deliveryrules[ruleIndex].calc_event_target_daily_total = rslt.data.calc_event_target_daily_total;            
            this.deliveryrules[ruleIndex].calc_duration_days = rslt.data.calc_duration_days;
            this.deliveryrules[ruleIndex].calcInProgress = false;                        
            let availCapacityRslt = await api.campaign.availablecapacity({
                campaignid: this.campaignid,
                priority: null,
                assetData: null,
                ruleData: this.deliveryrules[ruleIndex],
                otherRuleCodes: []
            });                        
            this.deliveryrules[ruleIndex].availableCapacity = availCapacityRslt.data.available_capacity_impr;
            this.deliveryrules[ruleIndex].totalCapacity = availCapacityRslt.data.total_capacity_impr;  
            this.deliveryrules[ruleIndex].capacityCalcInProgress = false;
            this.$forceUpdate();          
        },
        async fetchRuleAnalysisData(ruleIndex) {
            let rslt = await api.campaign.analysisdata({
                campaignid: this.campaignid,
                priority: null,
                assetData: null,
                ruleData: this.deliveryrules[ruleIndex],
                otherRuleCodes: []
            });            
            this.analysisdata = rslt.data;
        },
        addDeliveryRule() {            
            //this.selectedTab = 0;
            if(this.deliveryrules.find(a=>a.editMode)){
                this.showFailSnackbar("Please save the rule currently being edited, before adding a new one.");
                return;
            }
            let nextIndex = this.deliveryrules.length + 1;            
            let initDeliveryRuleData = {
                code: this.id + '-' + nextIndex + '-' + Date.now(),
                editMode: true,
                network: null,                
                asset: null,
                totalAdpoints: null,
                availableCapacity: null,
                totalCapacity: null,                
                filterfields: [[]],
                filtercriteria: null,
                eventType: null,
                startdate: this.startdate,
                starttime: this.starttime,
                enddate: this.enddate,
                endtime: this.endtime,
                customEventName: null,
                eventAmountGoal: null,
                dailyFrequencyCap: 0,

                calc_duration_days: null,
                calc_event_target_daily_total: null,
                calcInProgress: false,
                capacityCalcInProgress: false,
                impactCalcInProgress: false,

                errormsg: '',
                warningmsg: '',
                filteringTabValid: true,
                goalTabValid: true,                
                attributeToAdd: null,
                
            }
            //initDeliveryRuleData.filterfields
            // initDeliveryRuleData.filterfields[0].push(
            //     {
            //         value: "brandId",
            //         text: "Brand",
            //         criteria: {
            //             editMode: false,
            //             search_value: this.brand
            //         },
            //         type: "list",
            //         listItems: this.brandOptions
            //     }
            // );
            // initDeliveryRuleData.filterfields[0].push(
            //     {
            //         value: "deviceId",
            //         text: "Device Type",
            //         criteria: {
            //             editMode: true
            //         },
            //         type: "list",
            //         listItems: this.brandOptions
            //     },
            // );
            this.deliveryrules.push(initDeliveryRuleData);
            this.onAddDeliveryRuleFiltCondition(this.deliveryrules.length-1, {attributeToAdd: "brandId", orClauseIndex: 0})
            this.deliveryrules[this.deliveryrules.length-1][0];
            this.onCompleteDeliveryRuleCondition(this.deliveryrules.length-1);
        },
        editDeliveryRule(rule, ruleIndex) {
            // this.pollingScenarioRuleIndex = ruleIndex;
            // this.pollingScenarioInProgress=1;
            rule.editMode=true;            
            this.onCompleteDeliveryRuleCondition(ruleIndex);            
        },
        deleteDeliveryRule(index) {
            this.deliveryrules.splice(index, 1);            
        },    
        async calculateDeliveryRuleAnalytics(rule, rIndex) {
            this.pollingScenarioInProgress=1;
            this.pollingScenarioStatus_Previous='started';
            this.pollingScenarioStatus='started';
            this.pollingScenarioProgress = 0;
            this.pollingScenarioRuleIndex = rIndex;
            if(await this.saveAsync(false)) {
                await this.onCompleteDeliveryRuleCondition(rIndex);
                this.pollData();
                //await this.fetchRuleAnalysisData(rIndex);
            }            
        }, 
        saveDeliveryRule(rule) {    
            let canSave = true;        
            this.$refs.deliveryRuleForm.validate();
            if(!this.deliveryRuleFormValid && rule.network && rule.asset) {
                //one or more of the 'goal' tab fields is invalid
                rule.goalTabValid = false;
                this.showFailSnackbar("Validation errors found on 'GOAL' section.")
                canSave = false;
            }
            else {
                rule.goalTabValid = true;
            }
            if(rule.filterfields.length==0 || rule.filterfields.filter(f=>f.length == 0).length > 0) {
                rule.filteringTabValid = false;
                rule.errormsg = "One or more filtering conditions must be added to this rule.";
                this.showFailSnackbar("Validation errors found on 'FILTERING' section.")
                canSave = false;
            }
            else if(rule.filterfields.filter(f=> f.filter(f=>f.criteria.editMode==true).length>0).length > 0) {
                rule.filteringTabValid = false;
                rule.errormsg = "One or more conditions are still being edited. Please complete all conditions by clicking the green 'check' icon on each one."
                this.showFailSnackbar("Validation errors found on 'FILTERING' section.")
                canSave = false;
            }
            else {
                rule.filteringTabValid = true;                
                rule.errormsg = '';
            }
            if(!this.deliveryRuleFormValid) {
                canSave = false;
            }
            if(canSave) {
                rule.editMode=false;     
                this.save(false);   
            }            
        },        





        onSearch() {

        },
        onDeleteCondition(index, {orIndex, nameToDelete}) {            
            //this.deliveryrules[index].filterfields[orIndex] = this.deliveryrules[index].filterfields[orIndex].filter(f=>f.value != nameToDelete);

            const itemToDelete = this.deliveryrules[index].filterfields[orIndex].filter(f=>f.value == nameToDelete);
            if (itemToDelete) {
                this.deliveryrules[index].filterfields[orIndex].splice(this.deliveryrules[index].filterfields[orIndex].indexOf(itemToDelete));
            }

            //const itemToDelete = this.filterfields[index].find(f=>f.value == nameToDelete);
            // if(itemToDelete) {
            //     this.filterfields[index].splice(this.filterfields[index].indexOf(itemToDelete), 1);
            // }    
            this.onCompleteDeliveryRuleCondition(index);
        },
        onAddDeliveryRuleFiltCondition(index, {attributeToAdd, orClauseIndex}) {                 
            const selectedAttributeData = this.attributeOptions.find(o=>o.value==attributeToAdd);            
            if(!selectedAttributeData) 
                return;            
            this.deliveryrules[index].filterfields[orClauseIndex].push({
                value: selectedAttributeData.extra.name,
                text: selectedAttributeData.extra.description || selectedAttributeData.extra.name,
                criteria: {
                    editMode: true
                },
                type: selectedAttributeData.extra.type, // selectedAttributeData.extra.type.startsWith('tag') ? "list" : "text",
                listItems: selectedAttributeData.extra.valuelist.map(i=> {
                    if(!i.hasOwnProperty('value')) { 
                        return {value: i, text: i};
                    } 
                    else {
                        return i;
                    } 
                })
            });  
            //this.deliveryrules[index].attributeToAdd = null;
            //this.attributeToAdd = null;
        },
        addOrClause(index) {
            this.deliveryrules[index].filterfields.push([]);
        },
        onDeleteOrCondition(index, orIndex) {
            this.deliveryrules[index].filterfields.splice(orIndex, 1);
            this.onCompleteDeliveryRuleCondition(index);
        },
        //DELIVERY END



        //BUDGET START
        addBudgetRule() {
            //this.selectedTab = 0;
            if(this.budgetrules.find(a=>a.editMode)){
                this.showFailSnackbar("Please save the budget rule currently being edited, before adding a new one.");
                return;
            }
            let nextIndex = this.budgetrules.length + 1;
            let initBudgetData = {
                code: this.id + '-' + nextIndex + '-' + Date.now(),
                //isNew: true,
                editMode: true,
                
                asset: null,
                eventType: null,
                customEventName: null,
                eventCost: null,
                costType: null,
                budget: null
            }
            this.budgetrules.push(initBudgetData);            
            //console.log(this.$refs.assetType);
        },
        editBudgetRule(rule) {
            rule.editMode = true;
        }, 
        deleteBudgetRule(index) {
            this.budgetrules.splice(index, 1);            
        },
        saveBudgetRule(rule) {
            this.$refs.budgetRuleForm.validate();
            if(this.budgetRuleFormValid) {
                rule.editMode = false;
            }            
        }, 
        //BUDGET END

        releaseCampaign(showSuccessMessage=true) {
            const allValid = this.validateEverything();
            if(!allValid) {
                return;
            }
            this.loading = true;                           
            let saveData = this.createUpdateDto();
            api.campaign.release(this.id, saveData)
                .then((rsp)=>{   
                    this.status = rsp.data.status;
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign Archived succesfully.");
                    }                                     
                })
                .catch((error)=>{     
                    this.showFailSnackbar(error.response.data.message);                    
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        completeCampaign(showSuccessMessage=true) {
            const allValid = this.validateEverything();
            if(!allValid) {
                return;
            }
            this.loading = true;                           
            let saveData = this.createUpdateDto();
            api.campaign.complete(this.id, saveData)
                .then((rsp)=>{   
                    this.status = rsp.data.status;
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign Archived succesfully.");
                    }                                     
                })
                .catch((error)=>{     
                    this.showFailSnackbar(error.response.data.message);                    
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        archiveCampaign(showSuccessMessage=true) {
            const allValid = this.validateEverything();
            if(!allValid) {
                return;
            }
            this.loading = true;                           
            let saveData = this.createUpdateDto();
            api.campaign.archive(this.id, saveData)
                .then((rsp)=>{   
                    this.status = rsp.data.status;
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign Archived succesfully.");
                    }                                     
                })
                .catch((error)=>{     
                    this.showFailSnackbar(error.response.data.message);                    
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        createUpdateDto() {
            let startdatetime = getCtrlDateTime(this.startdate, this.starttime);
            let enddatetime = getCtrlDateTime(this.enddate, this.endtime);
            const rslt = {      
                campaignid: this.campaignid,
                description: this.description,
                status: this.status,
                brand: this.brand,
                priority: this.priority,
                startdatetime: startdatetime,
                enddatetime: enddatetime,
                targetnetworks: this.targetnetworks,
                assets: this.assets,              
                deliveryrules: this.deliveryrules,
                budgetrules: this.budgetrules,                    
            };
            return rslt;
        },
        save(showSuccessMessage=true) {            
            const allValid = this.validateEverything();
            if(!allValid) {
                return;
            }
            this.loading = true;                           
            let saveData = this.createUpdateDto();
            if(this.createMode) {
                api.campaign.create(saveData)
                .then((rsp)=>{   
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign saved succesfully.");
                    }                       
                    this.id = rsp.data.id;
                    this.crud_code = rsp.data.id;
                    if(this.assets.length) {
                        this.assets.splice(0,this.assets.length);
                        this.assets.push(...rsp.data.assets);
                    }
                    this.createMode = false;                    
                    window.history.pushState({}, null, '/mng/campaigns/edit/' + this.crud_code);                    
                })
                .catch((error)=>{     
                    this.showFailSnackbar(error.response.data.message);                    
                })
                .finally(()=>{
                    this.loading = false;
                })
            }  
            else {
                let saveData = this.createUpdateDto();
                api.campaign.update(this.id, saveData)
                .then((rsp)=>{   
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign saved succesfully.");
                    }                                     
                })
                .catch((error)=>{     
                    this.showFailSnackbar(error.response.data.message);                    
                })
                .finally(()=>{
                    this.loading = false;
                })
            }                     
        },     
        async saveAsync(showSuccessMessage=true) {            
            const allValid = this.validateEverything(true, true);
            if(!allValid) {
                return false;
            }
            this.loading = true;                           
            let saveData = this.createUpdateDto();
            if(this.createMode) {
                try {
                    let rsp = await api.campaign.create(saveData);
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign saved succesfully.");
                    }                       
                    this.id = rsp.data.id;
                    this.crud_code = rsp.data.id;
                    if(this.assets.length) {
                        this.assets.splice(0,this.assets.length);
                        this.assets.push(...rsp.data.assets);
                    }
                    this.createMode = false;                    
                    window.history.pushState({}, null, '/mng/campaigns/edit/' + this.crud_code);
                } catch (error) {
                    this.showFailSnackbar(error.response.data.message);
                }      
                finally {
                    this.loading = false;
                }          
            }  
            else {
                try {
                    let saveData = this.createUpdateDto();
                    let rsp = await api.campaign.update(this.id, saveData);
                    if(showSuccessMessage) {
                        this.showSuccessSnackbar("Campaign saved succesfully.");
                    }
                } catch (error) {
                    this.showFailSnackbar(error.response.data.message);   
                }
                finally {
                    this.loading = false;
                }
            }
            return true;                     
        },          
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
