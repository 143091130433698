import { getCtrlDateTime, reformatDate } from "./date-utils"

export const fieldNotEmpty = (v) => !!v || 'Field is required'
export const numberFieldNotEmpty = (v) => (typeof v !== 'undefined' && v!=null && v.toString().length > 0) || 'Field is required'
export const fieldMinLength = (v, minLength) => (v && v.length >= minLength) || `Field must be at least ${minLength} characters long`
export const fieldSelectionArrayNotEmpty = (v) => (v && v.length > 0) || `At least one item must be selected.`
export const endDateLaterThanStart = (v, endTime, startDate, startTime) => {
    if(!v || !endTime || !startDate || !startTime) {
        return true;
    }
    const endDate = (v.includes('/') ? reformatDate(v) : v);
    const enddatetime = getCtrlDateTime(endDate, endTime);
    const startdatetime = getCtrlDateTime(startDate, startTime);    
    return enddatetime > startdatetime ||  `End Date & Time must follow Start Date & Time`;
}
export const endTimeLaterThanStart = (v, endDate, startDate, startTime) => {
    if(!v || !endDate || !startDate || !startTime) {
        return true;
    }
    const enddatetime = getCtrlDateTime(endDate, v);
    const startdatetime = getCtrlDateTime(startDate, startTime);
    return enddatetime > startdatetime ||  `End Date & Time must follow Start Date & Time`;
}