
export function formatDate(date) {
    if (!date) return null
    const [year, month, day] = date.split('-')
    return `${day.substring(0,2)}/${month}/${year}`
}

export function reformatDate(date) {
    if (!date) return null
    const [day, month, year] = date.split('/')
    return `${year}-${month}-${day}`
}

export function parseDate (date) {
    if (!date) return null
    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
};

export function getCtrlDateTime(dateCtrlValue, timeCtrlValue) {
    let datetimeRslt = new Date(dateCtrlValue);
    datetimeRslt.setHours(parseInt(timeCtrlValue.split(':')[0]), parseInt(timeCtrlValue.split(':')[1]), 0);
    return datetimeRslt;
}

export function dateToISOString() {
    
}