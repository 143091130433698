<template>
    <v-menu        
        ref="menuDatepicker"
        v-model="menuDatepicker"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="newValue"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{on, attrs}">
            <v-text-field
                :label="label"
                :messages="messages"
                v-model="newValueFormatted"                                              
                readonly
                v-bind="attrs"
                v-on="on"                
                :clearable="true"    
                @click:clear="onClear"  
                :disabled="disabled"
                :rules="rules"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menuDatepicker" 
            v-model="newValue" 
            full-width
            @click:minute="$refs.menuDatepicker.save(newValue)"
            @input="onInput" 
            @emptied="onInput"
            >                    
        </v-time-picker>
    </v-menu>
</template>
<script>

export default {
    name: 'TimepickerPopup',
    props: {
        label: String,
        messages: Array,
        value: [String, Date],
        disabled: Boolean,
        rules: Array,
    },
    data() {
        return {            
            newValue: this.value,
            newValueFormatted: "",
            menuDatepicker: false,                     
        }
    },
    watch: {
        value(val) {
            this.newValue = val;
        },
        newValue: {
            immediate:true,
            handler(newVal, oldVal) {            
                this.newValueFormatted = this.formatDate(newVal);             
                //this.$emit('input', val);
            }
        }
    },
     methods: {
        onInput(event) {            
            //this.menuDatepicker = false;                 
            this.$emit('input', this.newValue);
        },
        onClear(event) {
            this.newValue = null;
            this.$emit('input', this.newValue);
        },
        parseDate(d) {
            return d; //parseDate(d);
        },
        formatDate(d) {
            return d; //formatDate(d);
        },
    },    
}
</script>
