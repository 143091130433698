export function statusCalc(campaign) {                        
    if(campaign.status === 'released') {
        let startdatetime = new Date(campaign.startdatetime);   
        let enddatetime = new Date(campaign.enddatetime);             
        if(enddatetime <= Date.now() ) {
            return 'completed';
        }
        else if(startdatetime <= Date.now() ) {
            return 'running';
        }
        else {
            return 'queued';
        }
    }            
    else if (campaign.status === 'completed') {
        let enddatetime = new Date(campaign.enddatetime);                
        if(enddatetime <= Date.now()) {
            return 'completed';
        }
        else {
            return 'stopped';
        }
    }
    return campaign.status;
}

export function getOptionDescription(optionList, optionValue){
    let matchedItem = optionList.find(o=>o.value===optionValue);
    let rslt = optionValue
    if(matchedItem) {
        rslt = matchedItem.text;
    }
    return rslt;
}