<template>
    <div style="margin-top: 12px;">        
        <!-- {{JSON.stringify(criteria)}} -->
        <div v-if="horizontalRendering">
            <template v-for="(orClause, index) in fieldsState">
                <v-row  class="pa-1 w-full" :class="{'orfilterclause': fieldsState.length > 1}" style="margin: 0px 0px 0px 0px !important;" :key="index">
                    <v-btn v-if="fieldsState.length>1" class="d-flex mb-1" style="width: 100%;" x-small outlined color="black" @click="() => { onDeleteOrClause(index) }" >
                        Remove OR Condition
                    </v-btn>
                    <v-col cols="3" v-for="(field, idx) in orClause" :key="field.value">                
                        <v-card-text class="pt-0 pl-0 pr-0">
                            <!-- <advanced-grid-filter :dynamic="dynamic" :field="field" :editable="false" v-model:filtervalue="field.criteria" @delete="onDelete" /> -->
                            <advanced-grid-filter :dynamic="dynamic" :editable="false" v-model="orClause[idx]" @complete="onComplete" @delete="(nameToDelete) => { onDelete(index, nameToDelete) }" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="3">                
                        <v-card-text class="pt-4 pl-4 pr-4 pb-4 rounded smoothborder h-full" style="display: flex; align-items: center; justify-content: center; flex-direction: column">
                            <template v-if="toggleAddAttribute[index]">
                                <v-autocomplete                        
                                    v-model="attributeToAdd"
                                    :items="attributeOptions"
                                    label="Attribute"      
                                    outlined       
                                    dense          
                                    @change="() => {$set(toggleAddAttribute, index, false); addCriteria(index)}"                                         
                                ></v-autocomplete>
                                <v-btn outlined @click="() => {addCriteria(index)}" color="primary">   
                                        <v-icon class="pr-2">mdi-plus</v-icon>                            
                                        Add <br/> Attribute
                                </v-btn>
                            </template>
                            <v-btn  v-if="!toggleAddAttribute[index]" class="d-flex" style="width: 100%;" small color="primary" @click="() => { $set(toggleAddAttribute, index, true); }" >
                                <v-icon class="pr-2">mdi-plus</v-icon> 
                                Add <br/> Attribute
                            </v-btn> 
                        </v-card-text>
                    </v-col>                
                </v-row> 
                <span v-if="index<fieldsState.length-1" :key="index + 's'" style="color: black">OR</span>
            </template>                             
        </div>
        <v-card-text v-if="!horizontalRendering" class="pt-0 pl-0 pr-0 d-flex">
            <v-btn class="flex -mr-5" small color="primary" dark @click="search" v-if="searchButtonVisible">
                Search
            </v-btn>
            <!-- <v-btn class="flex" small color="primary" outlined>
                Clear
            </v-btn> -->
        </v-card-text>        
        <template v-if="!horizontalRendering">    
            <template v-for="(orClause, index) in fieldsState" >
                <div class="pa-1" :class="{'orfilterclause': fieldsState.length > 1}" :key="index">
                    <v-btn v-if="fieldsState.length>1" class="d-flex mb-1" style="width: 100%;" x-small outlined color="black" @click="() => { onDeleteOrClause(index) }" >
                        Remove "OR" Cond.
                    </v-btn>
                    <v-card-text class="pt-0 pl-0 pr-0" v-for="(field, idx) in orClause" :key="field.value">
                        <!-- <advanced-grid-filter :dynamic="dynamic" :field="field" :editable="false" v-model:filtervalue="field.criteria" @delete="onDelete" /> -->
                        <advanced-grid-filter :dynamic="dynamic" :editable="false" v-model="orClause[idx]" @complete="onComplete" @delete="(nameToDelete) => { onDelete(index, nameToDelete) }" />
                    </v-card-text>  
                    <template v-if="toggleAddAttribute[index]">
                        <v-autocomplete             
                        class="mb-1"           
                        v-model="attributeToAdd"
                        :items="attributeOptions"
                        label="Attribute"      
                        outlined       
                        dense                           
                        hide-details="true"                                                  
                        @change="() => {$set(toggleAddAttribute, index, false); addCriteria(index)}"                                           
                        ></v-autocomplete>
                        <v-btn class="d-flex" style="width: 100%;" small color="primary" outlined @click="() => {addCriteria(index)}" >
                            Add Attribute
                        </v-btn>
                    </template> 
                    <v-btn  v-if="!toggleAddAttribute[index]" class="d-flex" style="width: 100%;" small color="primary" @click="() => { $set(toggleAddAttribute, index, true); }" >
                        Add Attribute
                    </v-btn>                               
                </div>                       
                <span v-if="index<fieldsState.length-1" :key="index + 's'">OR</span>
            </template>        
            
        </template>        
    </div>
</template>

<script>
import AdvancedGridFilterNew from "@/components/core/AdvancedGridFilterNew"

export default {
    name: 'AdvancedGridFiltersListNew',
    components: {
        "advanced-grid-filter": AdvancedGridFilterNew
    },
    props: {
        value: Array,
        dynamic: Boolean,
        filtervalue: Object,
        attributeOptions: {
            default: () => [],
            type: Array
        },
        searchButtonVisible: {
            default: true,
            type:  Boolean,
        },
        horizontalRendering: {
            default: false,
            type: Boolean
        }
    },
    // model: {
    //   prop: 'filtervalue',
    //   event: 'update'
    // },
    data() {
        
        return {
            select_field: "",   
            attributeToAdd: null,         
            fieldsState: this.value,// [...this.value],
            toggleAddAttribute: this.value.map(v=>false),
            criteria: [],
            qs: "",
        }
    },
    methods: {
        onComplete() {
            this.$emit('complete', {});
        },
        generateCriteria() {
            let index = 0;
            let f=null;
            let criteria = [];
            let criteriaQueryString = "&";
            for([index, f] of this.fieldsState.entries()) {
                let orCriteria = f.map(i => { return { name: i.value, operator: i.criteria.operator, search_value: i.criteria.search_value, type: i.type  } });
                for(let i=0; i<orCriteria.length; i++) {
                    const c = orCriteria[i];                         
                    if(c.name && c.operator && c.search_value) {
                        criteriaQueryString += index.toString() + "["+c.name + "][type]=" + c.type
                        criteriaQueryString += "&" + index.toString() + "[" + c.name + "][operator]=" + c.operator
                        if(Array.isArray(c.search_value)) {
                            c.search_value.forEach(v=> { 
                                //valueListItemsStripped: this.value.listItems.map(i=> { return i.hasOwnProperty('value') ? i.value : i; }),
                                if(v.hasOwnProperty('value')) {
                                    const grabbedValue = v.value;
                                    criteriaQueryString += "&" + index.toString() + "[" + c.name + "][search_value][]=" + encodeURIComponent(grabbedValue);
                                }
                                else {
                                    criteriaQueryString += "&" + index.toString() + "[" + c.name + "][search_value][]=" + encodeURIComponent(v);
                                }                                
                             })
                        }
                        else {
                            criteriaQueryString += "&" + index.toString() + "[" + c.name + "][search_value]=" + encodeURIComponent(c.search_value)                    
                        }                        
                        criteriaQueryString += "&"
                    }                
                }
                criteria.push(orCriteria);
            }
            //criteriaQueryString += (new URLSearchParams(criteria)).toString();

            // let criteria = this.fieldsState.map(i => { return { name: i.value, operator: i.criteria.operator, search_value: i.criteria.search_value, type: i.type  } });    
            // let criteriaQueryString = "&";
            // for(let i=0; i<criteria.length; i++) {
            //     const c = criteria[i];     
            //     if(c.name && c.operator && c.search_value) {
            //         criteriaQueryString += c.name + "[type]=" + c.type
            //         criteriaQueryString += "&" + c.name + "[operator]=" + c.operator
            //         criteriaQueryString += "&" + c.name + "[search_value]=" + encodeURIComponent(c.search_value)                    
            //         criteriaQueryString += "&"
            //     }                
            // }
            return [criteria, criteriaQueryString]
        },
        search() {
                                    
            this.$emit('search', {criteria: this.criteria, qs: this.qs})
            //console.log("\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n" + JSON.stringify(this.fields, null, 2) + "\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n");
        },
        onDelete(index, nameToDelete) {                                    
            this.$emit('delete',  {index, nameToDelete});            
        },
        onDeleteOrClause(index) {            
            this.$emit('deleteor', index);
        },
        addCriteria(orClauseIndex) {   
            const eventData = {attributeToAdd: this.attributeToAdd, orClauseIndex};                   
            this.$emit('addcriteria', eventData);   
            this.attributeToAdd = null;         
        }
    },
    watch: {
        fieldsState(val) {
            const [criteria, qs] = this.generateCriteria();
            this.criteria = criteria;
            this.qs = qs;
            //this.$emit('input', this.fieldState)
            console.log("\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n" + JSON.stringify(val, null, 2) + "\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n");
        }
    },
    mounted() {        
    }
}
</script>

<style lang="scss" scoped>
    .smoothborder {
        border: 1px lightgray solid;
    }

    .orfilterclause {
        border: 1px solid black; 
        border-radius: 5px;
    }
</style>