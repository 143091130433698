<template>
    <div v-if="!editable" >
        <v-card-text class="pt-0 pl-0 pr-0 pb-0 rounded" :class="!editMode ? 'smoothborder' : ''">
            <v-select     
                v-if="editMode"                                                 
                v-model="select_field"
                :items="[value]" 
                label="Field"  
                :readonly="!editable"    
                :append-icon="''" 
                hide-details
                single-line              
                dense      
                style="font-weight: bold"            
            />
            <!-- {{value.type}} -->
            <v-select    
                v-if="editMode"
                v-model="select_operator"
                :items="operators.filter(o => o.type.includes(value.type))"                 
                @input="onInput"
                label="Operator"                 
                hide-details
                single-line                       
                dense                 
            />
            <v-text-field 
                v-if="editMode && value.type==='text'"
                v-model="search_value"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                label='(Type Filter Value)'
                @input="onInput"                
            />
            <v-combobox                         
                v-if="editMode && (value.type === 'tagsingle' || value.type === 'tagmultiple')"
                v-model="search_value"
                :items="value.listItems"                 
                label="Select List Item" 
                @input="onInput"
                hide-details
                single-line                       
                dense       
                :clearable="true"   
                multiple
                        
            />
            <v-select                         
                v-if="editMode && value.type === 'list'"
                v-model="search_value"
                :items="value.listItems"                 
                label="Select List Item" 
                @input="onInput"
                hide-details
                single-line                       
                dense       
                :clearable="true"                   
            />
            <!-- <v-spacer /> -->
            <!-- <v-numeric 
                v-if="value.type==='number'"
                style="margin-top: 16px !important;"
                text 
                :outlined="false"                
                :clearable="true"
                dense
                :persistent-hint="false"
                v-model="search_value"
                :label= " (select_operator==='betweenexcl' || select_operator==='betweenincl') ? '(From...)' : '(Type Filter Value)' "  
                @input="onInput"   
                useCalculator="false"  
                calcIcon=""           
                >
                
            </v-numeric> -->
            <v-text-field 
                v-if="editMode && value.type==='number'"
                v-model.number="search_value"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                :label= " (select_operator==='betweenexcl' || select_operator==='betweenincl') ? '(From...)' : '(Type Filter Value)' "
                @input="onInput"                  
                type="number"                             
            />
            <!-- <v-numeric 
                v-if="value.type==='number' && (select_operator==='betweenexcl' || select_operator==='betweenincl')"
                text 
                outlined 
                v-model="search_valueTo"
                label='(To...)'
                @input="onInput"                
                >
                
            </v-numeric> -->
            <v-text-field 
                v-if="editMode && value.type==='number' && (select_operator==='betweenexcl' || select_operator==='betweenincl')"
                v-model.number="search_valueTo"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                label='(To...)'
                @input="onInput"  
                type="number"                             
            />


            <!-- <v-menu
                v-if="value.type==='date'"
                ref="menuDatepicker"
                v-model="menuDatepicker"
                :close-on-content-click="false"
                :return-value.sync="search_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(Click to Choose a Date)'
                        v-model="search_date"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menuDatepicker = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menuDatepicker.save(search_date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu> -->

            <v-menu
                v-if="editMode && value.type==='date'"
                ref="menuDatepicker"
                v-model="menuDatepicker"
                :close-on-content-click="false"
                
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(Click to Choose a Date)'
                        v-model="search_date_formatted"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details    
                        :clearable="true"    
                        @click:clear="onClear"                
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_date" no-title scrollable @input="onInput" @emptied="onInput">                    
                </v-date-picker>
            </v-menu>

            <v-menu 
                v-if="editMode && value.type==='date' && (select_operator==='betweenexcl' || select_operator==='betweenincl') "
                ref="menuDatepickerTo"
                v-model="menuDatepickerTo"
                :close-on-content-click="false"                
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(To...)'
                        v-model="search_dateTo_formatted"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        :clearable="true"    
                        @click:clear="onClear" 
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_dateTo" no-title scrollable @input="onInput">                    
                </v-date-picker>
            </v-menu>
            <div v-if="editMode && dynamic" class="d-flex justify-space-between py-2">
                <v-icon color="primary" @click="onDelete">mdi-trash-can-outline</v-icon>
                <v-icon color="success" @click="(e) => {onCompleteCondition(e)}">mdi-check-circle</v-icon>
            </div>
            <div v-if="!editMode" class="d-flex justify-space-between pt-2 pb-1 px-3">                                
                <span style="font-weight: bold;">{{value.text}}</span>
                <span>{{getOptionDescription(operators, select_operator)}}</span>                
            </div>
            <div v-if="!editMode" class="d-flex justify-space-between pb-2 px-3">   
                <span v-if="value.type !== 'date'">{{(value.type !== 'tagsingle' && value.type !== 'tagmultiple' && value.type !== 'list' ) ? search_value : getOptionDescription(value.listItems, search_value)}} {{(value.type !== 'tagsingle' && value.type !== 'tagmultiple') && (select_operator==='betweenexcl' || select_operator==='betweenincl') ? ' - ' + search_valueTo : ''}}</span>             
                <span v-if="value.type === 'date'">{{search_date_formatted }} {{(select_operator==='betweenexcl' || select_operator==='betweenincl') ? ' - ' + search_dateTo_formatted : ''}}</span>             
                <v-icon color="secondary" style="margin-right: 10px;" @click="(e) => {editMode=true; onInput(e)}">mdi-pencil-box-outline</v-icon>
            </div>
        </v-card-text>     
    </div> 
    <div v-else>
    </div> 
</template>

<script>
import {parseDate, formatDate} from '@/core/date-utils.js'

export default {
    name: 'AdvancedGridFilterNew',
    props: {
        value: Object,        
        editable: Boolean,
        dynamic: Boolean
    },
    // model: {
    //   prop: 'filtervalue',
    //   event: 'update'
    // },

    data() {                
        return {            
            editMode: (this.value.hasOwnProperty('criteria') && this.value.criteria.hasOwnProperty('editMode') ? this.value.criteria.editMode : true),
            menuDatepicker: false,
            menuDatepickerTo: false,
            select_field: this.value,
            select_operator: (this.value.hasOwnProperty('criteria') && this.value.criteria.hasOwnProperty('operator') ? this.value.criteria.operator : 
                ((this.value.type === 'tagsingle' || this.value.type === 'tagmultiple' || this.value.type === 'text') ? "contains" :  "equals")
            ) ,
            search_value: (this.value.hasOwnProperty('criteria') && this.value.criteria.hasOwnProperty('search_value') ? this.value.criteria.search_value : ""),
            search_valueTo: "",
            search_valueItems: [],
            search_date: "", //new Date().toISOString().substr(0, 10),
            search_date_formatted: "", //new Date().toISOString().substr(0, 10),
            search_dateTo: "", //new Date().toISOString().substr(0, 10),                        
            search_dateTo_formatted: "", //new Date().toISOString().substr(0, 10),      
            valueListItemsStripped: this.value.listItems.map(i=> { return i.hasOwnProperty('value') ? i.value : i; }),
            operators: [
                {
                    value: "contains",
                    text: "Contains",
                    type: ["text", "tagmultiple"]
                },    
                {
                    value: "containsone",
                    text: "Contains Any Of",
                    type: ["tagmultiple"]
                },
                {
                    value: "subsetof",
                    text: "Subset Of",
                    type: ["tagmultiple", "tagsingle"]
                },           
                {
                    value: "startswith",
                    text: "Starts with",
                    type: ["text"]
                },
                {
                    value: "endswith",
                    text: "Ends with",
                    type: ["text"]
                },
                {
                    value: "equals",
                    text: "Equals =",
                    type: ["text", "number", "date", "tagsingle", "tagmultiple", "list"]
                },
                {
                    value: "lessthan",
                    text: "Less Than <",
                    type: ["number", "date"]
                },
                {
                    value: "lessthanorequal",
                    text: "Less Than/Equal <=",
                    type: ["number", "date"]
                },
                {
                    value: "morethan",
                    text: "More Than >",
                    type: ["number", "date"]
                },
                {
                    value: "morethanorequal",
                    text: "More Than/Equal >=",
                    type: ["number", "date"]
                },
                {
                    value: "betweenexcl",
                    text: "Between Excl. [ ]",
                    type: ["number", "date"]
                },
                {
                    value: "betweenincl",
                    text: "Between Incl. ( )",
                    type: ["number", "date"]
                },
            ]
        }
    },
    mounted() {
        this.select_field = this.value;
    },
    watch: {
      search_date(val) {                  
        // const t = this.formatDate(this.search_date);
        // console.log(t);
        this.search_date_formatted = formatDate(this.search_date);
        if(this.search_dateTo) {
            this.search_dateTo_formatted = formatDate(this.search_dateTo);
        }
      },
      search_dateTo(val) {                  
        // const t = this.formatDate(this.search_date);
        // console.log(t);
        this.search_dateTo_formatted = formatDate(this.search_dateTo);        
      },
    },
    methods: {        
        onCompleteCondition(e) {
            this.editMode=false; 
            this.onInput(e);
            this.$emit('complete', {});
        },
        getOptionDescription(optionList, optionValue){
            let rslt = "";
            if(!optionList || !optionValue) {
                return "";
            }
            if(!Array.isArray(optionValue)){
                let matchedItem = optionList.find(o=>o.value===optionValue);
                rslt = optionValue
                if(matchedItem) {
                    rslt = matchedItem.text;
                }
            }
            else {
                for(let opt of optionValue) {
                    let descrText = opt;     
                    let loopRslt = '';               
                    if(opt.hasOwnProperty("text")) {
                        descrText = opt.text;
                    }
                    if(opt.hasOwnProperty("value")) {
                        descrText = opt.value;
                    }
                    let matchedItem = optionList.find(o=>o.value===descrText);
                    loopRslt = descrText;
                    if(matchedItem) {
                        loopRslt = matchedItem.text;
                    }
                    rslt += loopRslt + ', ';                    
                }
                if(rslt.trimEnd().length>0) {
                    rslt = rslt.trimEnd().slice(0, -1);
                }
            }            
            return rslt;
        },
        parseDate(d) {
            return parseDate(d);
        },
        formatDate(d) {
            return formatDate(d);
        },
        onDelete() {                    
            this.$emit('delete',  this.select_field.value);
        },
        onClear(event) {                    
            this.menuDatepicker = false;
            this.menuDatepickerTo = false;

            if(this.value.type === 'text') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('input',  {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator}})   
            }  
             if(this.value.type === 'tagsingle' || this.value.type === 'tagmultiple' || this.value.type === 'list') {
                 this.search_value = "";
                 this.search_valueTo = "";
                this.$emit('input',  {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator}})   
            }           
            if(this.value.type === 'number') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('input', {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator}})   
            }
            if(this.value.type === 'date') {
                this.search_date = "";
                this.search_dateTo = "";
                this.$emit('input', {...this.value, criteria: {search_value: this.search_date, operator: this.select_operator}})   
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.value.type === 'number') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('input', {...this.value, criteria: {search_value: this.search_value + " " + this.search_valueTo, operator: this.select_operator}});
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.value.type === 'date') {
                this.search_date = "";
                this.search_dateTo = "";
                this.$emit('input', {...this.value, criteria: {search_value: this.search_date + " " + this.search_dateTo, operator: this.select_operator}})   
            }
        },
        onInput(event) {                    
            this.menuDatepicker = false;
            this.menuDatepickerTo = false;
            if (!(this.select_field || this.select_operator)) {                
                this.$emit('input', {...this.value, criteria: {search_value: "", operator: this.select_operator, editMode: this.editMode}})
            }
            if(this.value.type === 'text') {
                this.$emit('input',  {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode}})   
            }  
             if(this.value.type === 'tagsingle' || this.value.type === 'tagmultiple' || this.value.type === 'list') {                
                this.$emit('input',  {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode}})   
            }           
            if(this.value.type === 'number') {
                this.$emit('input', {...this.value, criteria: {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode}})   
            }
            if(this.value.type === 'date') {
                this.$emit('input', {...this.value, criteria: {search_value: this.search_date, operator: this.select_operator, editMode: this.editMode}})   
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.value.type === 'number') {
                this.$emit('input', {...this.value, criteria: {search_value: this.search_value + " " + this.search_valueTo, operator: this.select_operator, editMode: this.editMode}});
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.value.type === 'date') {
                this.$emit('input', {...this.value, criteria: {search_value: this.search_date + " " + this.search_dateTo, operator: this.select_operator, editMode: this.editMode}})   
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .smoothborder {
        border: 1px lightgray solid;
    }
</style>
