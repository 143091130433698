<template>
    <v-menu        
        ref="menuDatepicker"
        v-model="menuDatepicker"
        :close-on-content-click="false"        
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{on, attrs}">            
            <v-text-field
                :label="label"
                :messages="messages"
                v-model="newValueFormatted"                                              
                readonly
                v-bind="attrs"
                v-on="on"                
                :clearable="true"    
                @click:clear="onClear"  
                :disabled="disabled"
                :rules="rules"
            ></v-text-field>
        </template>
        <v-date-picker v-model="newValue" no-title scrollable @input="onInput" @emptied="onInput">                    
        </v-date-picker>
    </v-menu>
</template>
<script>
import {parseDate, formatDate} from '@/core/date-utils.js'

export default {
    name: 'DatepickerPopup',
    props: {
        label: String,
        messages: Array,
        value: [String, Date],
        disabled: Boolean,
        rules: Array,
    },
    data() {        
        return {            
            newValue: this.value, //  '2020-07-17',
            newValueFormatted: "",
            menuDatepicker: false,                     
        }
    },    
    watch: {
        value(val) {
            this.newValue = val;
        },
        newValue: {
            immediate:true,
            handler(newVal, oldVal) {            
                this.newValueFormatted = formatDate(newVal);             
                //this.$emit('input', val);
            }
        }
    },
     methods: {
        onInput(event) {
            this.menuDatepicker = false;                 
            this.$emit('input', this.newValue);
        },
        onClear(event) {
            this.newValue = null;
            this.$emit('input', this.newValue);
        }
    },
    created() {
        //alert(this.value);
    }
    // parseDate(d) {
    //     return parseDate(d);
    // },
    // formatDate(d) {
    //     return formatDate(d);
    // },
}
</script>
